import React from 'react';
import { useFormikContext } from 'formik';
import FormTextField from '../components/FormTextField';
import FormCheckBox from '../components/FormCheckBox';
import FormSelect from '../components/FormSelect';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormDateFields from '../components/FormDateFields';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PasswordStrengthBar from 'react-password-strength-bar';
import combineStyles from '../utils/combineStyles';
import { logEvent } from '../utils/analytics';
import getTermsAndConditionsLink from '../selectors/getTermsAndConditionsLink';
import { useAppSelector } from '../app/hooks';
import { EVENT_CATEGORIES } from '../constants/events';
import { useTranslation } from 'react-i18next';
import { determineEndpoint } from '../utils/endpoint';
import getThemeLookup from '../selectors/getThemeLookup';
import { TEXT_PROPERTIES } from '../utils/theme';

const Link: React.FC<{
  url: string;
  openInNewTab?: boolean;
  onClick?: () => void;
  children?: React.ReactChildren;
}> = ({ url, openInNewTab, onClick, children }) => {
  const p = useAppSelector(getThemeLookup);
  return (
    <a
      href={url}
      style={combineStyles(styles.link, p('signInLink', ['fontFamily', 'fontWeight']))}
      target={openInNewTab ? '_blank' : undefined}
      onClick={onClick}
    >
      {children}
    </a>
  );
};

const RegistrationFields = ({ field }: { field: RegisterFields }) => {
  const formik = useFormikContext<FormikFields>();
  const { values, handleBlur, handleChange } = formik;
  const termsAndConditionsLink = useAppSelector(getTermsAndConditionsLink);
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);

  const _getTermsAndConditionsLink = () => {
    let _termsAndConditionsLink;
    try {
      _termsAndConditionsLink = String(new URL(termsAndConditionsLink!));
    } catch (e) {
      _termsAndConditionsLink = String(determineEndpoint()! + termsAndConditionsLink);
    }
    return (
      <span>
        {t('termsPrefix')}{' '}
        <Link url={_termsAndConditionsLink} openInNewTab={true}>
          {t('termsAndContition')}
        </Link>
      </span>
    );
  };

  const renderFormField = () => {
    const { inputType, label } = field;

    switch (inputType) {
      case 'email':
      case 'number':
      case 'text':
      case 'password':
        return (
          <FormControl key={field.id} fullWidth>
            <FormTextField
              name={field.id}
              type={inputType === 'number' ? 'digits' : field.inputType}
              onChange={handleChange}
              label={label!}
              required={field.mode === 'required'}
              disabled={field.editMode === 'display'}
            />
            {inputType === 'password' && field.id !== 'ConfirmPassword' && (
              <PasswordStrengthBar
                password={String(values[field.id as keyof FormikFields])}
                style={combineStyles(styles.passwordStrengthBar)}
                scoreWordStyle={combineStyles(p('defaultText', TEXT_PROPERTIES), {
                  fontSize: '0.6rem',
                  opacity: '0.6',
                })}
              />
            )}
          </FormControl>
        );
      case 'sendemail':
      case 'sendsms':
      case 'terms':
      case 'checkbox':
        return (
          <FormControl key={field.id} fullWidth>
            <FormCheckBox
              required={field.mode === 'required'}
              onChange={handleChange}
              name={field.id}
              disabled={field.editMode === 'display'}
              label={inputType === 'terms' ? _getTermsAndConditionsLink() : label}
            />
          </FormControl>
        );
      case 'radio':
        return (
          <RadioGroup
            id={field.id}
            name={field.id}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values[field.id as keyof FormikFields]}
          >
            {field.possibleValues?.map(({ value, label }: any, index: number) => (
              <FormControlLabel
                key={index}
                value={value}
                control={
                  <Radio
                    disabled={field.editMode === 'display'}
                    required={field.mode === 'required'}
                  />
                }
                label={label}
              />
            ))}
          </RadioGroup>
        );
      case 'select':
        return (
          <FormControl key={field.id} fullWidth>
            <FormSelect
              required={field.mode === 'required'}
              label={label}
              name={field.id}
              onChange={handleChange}
              disabled={field.editMode === 'display'}
              menuItems={field?.possibleValues}
            />
          </FormControl>
        );
      case 'date':
      case 'datetime':
      case 'time':
        return (
          <FormControl key={field.id} fullWidth>
            <FormDateFields
              type={field.inputType}
              disabled={field.editMode === 'display'}
              required={field.mode === 'required'}
              name={field.id}
              label={label}
              onChange={handleChange}
            />
          </FormControl>
        );
      default:
        logEvent(
          { action: 'noComponentForField', category: EVENT_CATEGORIES.FAILURE },
          { label: 'noComponentForField', value: JSON.stringify(field) },
        );
        return null;
    }
  };

  return <div>{renderFormField()}</div>;
};

const styles: Styles = {
  passwordStrengthBar: {
    marginTop: '1rem',
    fontSize: '0.7rem',
  },
  link: {
    marginTop: '1rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: '0.8rem',
  },
};

export default RegistrationFields;
