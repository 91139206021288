import * as Yup from 'yup'; // https://github.com/jquense/yup/issues/216
import moment from 'moment';
import zxcvbn from 'zxcvbn';
import lodash from 'lodash';
import i18next from 'i18next';
import EVENTS from '../constants/events';

// utils
import * as analytics from './analytics';

function addLengthValidators(field: RegisterFields, validator: Validator) {
  let newValidator = validator;

  if (field.maxLength) {
    newValidator = (newValidator as Yup.StringSchema).max(
      field.maxLength,
      `${i18next.t('message.maxCharactersStart')}${field.maxLength}${i18next.t(
        'message.maxCharactersEnd',
      )}`,
    );
  }

  if (field.minLength) {
    newValidator = (newValidator as Yup.StringSchema).min(
      field.minLength,
      `${i18next.t('message.minCharactersStart')}${field.minLength}${i18next.t(
        'message.minCharactersEnd',
      )}`,
    );
  }

  return newValidator;
}

export function addPasswordSecurityValidators(
  validator: Validator,
  passwordComplexity: number | null,
) {
  let newValidator = validator;
  if (passwordComplexity) {
    newValidator = newValidator.test('secure', '', function (value) {
      if (value) {
        try {
          const {
            path,
            createError,
            parent: { Email, GivenNames, Surname, Mobile },
          } = this;

          const user_inputs = [Email, GivenNames, Surname, Mobile];

          const result = zxcvbn(value as string, user_inputs);

          if (result.score < passwordComplexity) {
            return createError({
              path,
              message: lodash.get(result, 'feedback.warning') || 'Password not secure enough',
            });
          }
        } catch (e) {
          // do nothing
        }
      }

      return true;
    });
  }

  return newValidator;
}

export function isReadOnly(field: RegisterFields, value: string) {
  try {
    return fieldConfig[field.id].overrides?.readOnly === value ? 'display' : field.editMode;
  } catch (e) {
    return field.editMode;
  }
}

type Validator = Yup.StringSchema | Yup.MixedSchema | Yup.BooleanSchema;

function validatorForField(field: RegisterFields, config: ConfigState) {
  const { inputType } = field;

  let validator: Validator = Yup.string();

  const isRequired = field.mode === 'required' || field.required;
  const isReadonly = field.mode === 'display';

  if (isReadonly) {
    return validator;
  }

  if (field.confirmsFieldId) {
    //@ts-ignore
    return validator.oneOf([Yup.ref(field.confirmsFieldId)], 'Values do not match');
  }

  switch (inputType) {
    case 'email': {
      validator = controlledDomainEmailValidator();
      validator = addLengthValidators(field, validator);
      break;
    }
    case 'password': {
      validator = Yup.string();
      validator = addPasswordSecurityValidators(validator, config.passwordComplexity);
      validator = addLengthValidators(field, validator);
      break;
    }
    case 'text':
    case 'legend':
    case 'hidden': {
      validator = Yup.string();
      validator = addLengthValidators(field, validator);
      if (field.appAlias === fieldConfig.Mobile.appAlias) {
        validator = Yup.string().matches(
          config.internationalMobileAllowed!
            ? config.mobileRegexWhiteList!
              ? new RegExp(config.mobileRegexWhiteList!)
              : config.mobileRegexInt!
            : config.mobileRegexAU!,
          'Invalid mobile number',
        );
      }
      break;
    }
    case 'radio':
    case 'select': {
      validator = Yup.mixed();
      if (isRequired && field.possibleValues) {
        validator = validator.oneOf(field.possibleValues.map(option => option.value));
      }
      break;
    }
    case 'checkbox':
    case 'sendsms':
    case 'sendemail': {
      validator = Yup.boolean();
      break;
    }
    case 'terms': {
      validator = Yup.boolean().test(
        'Terms',
        'You must agree to the terms and conditions',
        value => {
          return value ? true : false;
        },
      );
      break;
    }
    case 'url': {
      validator = Yup.string().url('Invalid url format');
      validator = addLengthValidators(field, validator);
      break;
    }
    case 'time': {
      // TODO
      break;
    }
    case 'date': {
      // TODO
      break;
    }
    case 'datetime': {
      // TODO
      break;
    }
    case 'number':
    case 'tel': {
      validator = Yup.string().matches(/^[0-9]+$/, 'Only the digits 0-9 are valid');
      validator = addLengthValidators(field, validator);
      break;
    }
    default:
      analytics.logEvent(EVENTS.VALIDATOR_FAILED, {
        label: 'noValidatorForField',
        value: JSON.stringify(field),
      });
      break;
  }

  if (isRequired) {
    validator = validator.required(
      `${field.label} ${
        field.label.endsWith('s') ? i18next.t('form.areRequired') : i18next.t('form.isRequired')
      } ${i18next.t('form.requiredField')}`,
    );
  }

  return validator;
}

export function isNotExcluded(field: ProfileFields, value: string) {
  try {
    return fieldConfig[field.id].overrides?.excludeFrom !== value;
  } catch (e) {
    return true;
  }
}

type Schema = {
  [key: string]: Validator;
};

export function generateSchemaForFields(fields: RegisterFields[], config: ConfigState) {
  const validators: Schema = {};
  if (fields) {
    fields.forEach(field => {
      validators[field.id] = validatorForField(field, config);
    });

    return Yup.object().shape(validators);
  }
}

export function partitionFieldsByVisibility(fields: any) {
  const partitioned: {
    visible: any;
    hidden: any;
  } = { visible: [], hidden: [] };
  fields.forEach((field: any) => {
    partitioned[field.inputType === 'hidden' ? 'hidden' : 'visible'].push(field);
  });

  return partitioned;
}

//@ts-ignore
export function getInitialFieldValues(fields?: RegisterFields[], overrides?: Profile = {}) {
  const initialValues: FormikFields = {};

  if (fields) {
    fields.forEach(field => {
      const { inputType, id, defaultValue } = field;
      let value;

      try {
        switch (inputType) {
          case 'email':
          case 'text':
          case 'password':
          case 'legend':
          case 'url':
          case 'number':
          case 'tel':
          case 'radio':
          case 'select': {
            value = undefined;
            break;
          }
          case 'checkbox':
          case 'sendemail':
          case 'sendsms':
          case 'terms': {
            value = !!defaultValue;
            break;
          }
          case 'time': {
            value = defaultValue ? moment(defaultValue).format('hh:mm:ss') : undefined;
            break;
          }
          case 'date': {
            value = defaultValue ? moment(defaultValue).format('DD-MM-YYYY') : undefined;
            break;
          }
          case 'datetime': {
            value = defaultValue ? moment(defaultValue).format('YYYY-MM-DD hh:mm:ss') : undefined;
            break;
          }
          default:
            value = defaultValue;
        }
      } catch (e) {
        value = undefined;
      }

      const bar = fieldConfig[`${id}`] || {};

      if (bar.appAlias && overrides) {
        const foo = overrides[bar.appAlias as keyof Profile];
        if (foo !== undefined && foo !== null) {
          //@ts-ignore
          initialValues[id] = foo;
        } else {
          //@ts-ignore
          initialValues[id] = value;
        }
      }
    });
  }

  return initialValues;
}

export const memberNumberValidator = Yup.string()
  .matches(/^(\d{3}|[a-zA-Z]{3,4})\d{7,8}$/, 'Invalid MemberNo')
  .required('Required');

// TODO: add blacklist support
export function controlledDomainEmailValidator(whitelist = []) {
  if (whitelist && whitelist.length) {
    return Yup.string().test('test-name', 'Invalid email address', value =>
      Boolean(whitelist.find(domain => (value || '').endsWith(domain))),
    );
  }

  return Yup.string().email('Invalid email address');
}

export const profileFieldConfig: OtherProfileFieldsConfig = {
  DeviceID: { appAlias: 'deviceId' },
  PackageName: { appAlias: 'version' },
  DeviceType: { appAlias: 'os' },
  MemberNo: {
    appAlias: 'memberNumber',
    formGroup: 'Card',
    description: i18next.t('form.tapToScanBarcode'),
  },
  VerifyPIN: {
    appAlias: 'cardVerificationCode',
    formGroup: 'Card',
    description: i18next.t('form.cardVerificationCode'),
  },
  Barcode: { appAlias: 'barcode', formGroup: 'Card' },

  Email: {
    appAlias: 'emailAddress',
    formGroup: 'Account',
    overrides: { readOnly: 'updateProfileScreen' },
  },
  ConfirmEmail: { overrides: { excludeFrom: 'updateProfileScreen' } },
  Password: {
    appAlias: 'password',
    formGroup: 'Account',
    overrides: { excludeFrom: 'updateProfileScreen' },
  },
  ConfirmPassword: { overrides: { excludeFrom: 'updateProfileScreen' } },
  Username: { appAlias: 'username', formGroup: 'Account' },

  GivenNames: { appAlias: 'givenNames', formGroup: 'Details' },
  Surname: { appAlias: 'surname', formGroup: 'Details' },
  Sex: {
    appAlias: 'gender',
    formGroup: 'Details',
    overrides: {
      excludeFrom: 'registerProfileScreen',
      label: 'Gender',
    },
  },
  DateOfBirth: {
    appAlias: 'dateOfBirth',
    formGroup: 'Details',
    description: i18next.t('form.birthdayRequestReason'),
  },

  Mobile: { appAlias: 'mobileNumber', formGroup: 'Contact' },
  Phone: { appAlias: 'phoneNumber', formGroup: 'Contact' },

  SendEmail: { appAlias: 'sendEmail', formGroup: 'Options' },
  SendSMS: { appAlias: 'sendSMS', formGroup: 'Options' },
  StoreID: { appAlias: 'favouriteLocation', formGroup: 'Options' },
  Referrer: { appAlias: 'referrer', formGroup: 'Options' },

  Address: { appAlias: 'address', formGroup: 'Address' },
  Suburb: { appAlias: 'suburb', formGroup: 'Address' },
  Postcode: { appAlias: 'postCode', formGroup: 'Address' },
  State: { appAlias: 'state', formGroup: 'Address' },
  Country: { appAlias: 'country', formGroup: 'Address' },

  ID: { appAlias: 'id' },
  Active: { appAlias: 'active' },
  GroupID: { appAlias: 'groupId' },
  ExpiryDate: { appAlias: 'expiryDate' },
  RegistrationDate: { appAlias: 'registrationDate' },
  VerificationDate: { appAlias: 'verificationDate' },
  CardType: { appAlias: 'cardType' },
  LoyaltyTier: { appAlias: 'loyaltyTier' },
  LoyaltyTierName: {
    appAlias: 'loyaltyTierName',
  },
  PromotionElegibleLoyaltySpendings: {
    appAlias: 'dollarsUntilNextTier',
  },
};

export const fieldConfig = { ...profileFieldConfig };
