import React, { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { useTranslation } from 'react-i18next';
import RedcatImage from '../components/RedcatImage';
import RedcatBarcode from '../components/RedcatBarcode';
import moment from 'moment';
import Text from '../components/Text';
import getProfile from '../selectors/getProfile';
import TouchableOpacity from '../components/TouchableOpacity';
import { setCurrentModal } from '../thunks/setCurrentModal';
import { setLoyaltySelectedOffer } from '../slices/loyaltySelectedOffer';
import { LOYALTY_OFFER_MODAL_ID } from '../modals/LoyaltyOfferModal';
import SendVerificationButton from '../components/SendVerificationButton';

export const OFFERS_ROUTE = '/offers';

const OffersScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const offers = useAppSelector(state => state.offers);
  const [, forceUpdate] = useState({});
  const profile = useAppSelector(getProfile);

  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(() => forceUpdate({}), 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const getBarcodeOptions = (offer: MemberOffer) => {
    const barcodeOptions: BarcodeOptions = {
      value: offer.Barcode,
    };
    return barcodeOptions;
  };

  let sortedOffers = offers && [...offers];

  sortedOffers = sortedOffers
    ? sortedOffers!
        .map(offer => ({
          ...offer,
          expired: !!offer.ExpiryDate && moment(offer.ExpiryDate) < moment(),
          unstarted: !!offer.StartDate && moment(offer.StartDate) > moment(),
        }))
        .filter(offer => {
          if (offer.expired) {
            return false;
          }
          if (offer.unstarted) {
            return false;
          }
          return true;
        })
    : [];

  return (
    <div className="text-center">
      {!profile?.verified ? (
        <div style={{ padding: '20px 0px' }}>
          <Text value={t('offersIfNotVerified')} style={{ fontSize: 14 }} />
          <SendVerificationButton containerStyle={{ paddingTop: 20 }} />
        </div>
      ) : (
        <>
          {sortedOffers?.map((offer: MemberOffer) => (
            <TouchableOpacity
              key={offer.ID}
              onClick={() => {
                dispatch(setLoyaltySelectedOffer(offer!));
                dispatch(setCurrentModal(LOYALTY_OFFER_MODAL_ID));
              }}
            >
              <div
                style={{
                  backgroundColor: 'whitesmoke',
                  borderRadius: 5,
                  padding: 20,
                  margin: 10,
                }}
              >
                {offer.ImageURL && <RedcatImage imagePath={offer.ImageURL} imgFluid />}
                <div className="my-2">
                  <div style={{ backgroundColor: 'white' }}>
                    <Text value={offer.ProgramName} style={{ fontWeight: 'bold', fontSize: 12 }} />{' '}
                    <br />
                    <Text
                      value={
                        'Valid ' +
                        moment(offer.StartDate).format('l') +
                        ' - ' +
                        moment(offer.ExpiryDate).format('l')
                      }
                      style={{ fontSize: 12 }}
                    />
                    <div>
                      <Text value={'More Details..'} style={{ fontWeight: 'bold', fontSize: 12 }} />{' '}
                    </div>
                  </div>

                  <RedcatBarcode barcodeOptions={getBarcodeOptions(offer)} showText={false} />
                </div>
              </div>
            </TouchableOpacity>
          ))}
        </>
      )}
    </div>
  );
};

export default OffersScreen;
