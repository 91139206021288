import { ActionType, getType } from 'typesafe-actions';

import * as config from '../../actionCreators/config';

export default function (
  state: number = 0,
  action: ActionType<typeof config>,
): number {
  switch (action.type) {
    case getType(config.setFutureOrderingLimit): {
      const { value } = action.payload;
      return Number(value);
    }
    default:
      return state;
  }
}
