import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@material-ui/core';
import getThemeLookup from '../selectors/getThemeLookup';
import { useFormikContext } from 'formik';

const FormTextField = ({
  id,
  name,
  type,
  label,
  required = false,
  onChange,
  disableStyles = false,
  disabled,
}: {
  id?: string;
  name: string;
  type: string;
  required?: boolean;
  label: string;
  onChange: (e: any) => void;
  disableStyles?: boolean;
  disabled?: boolean;
}) => {
  const p = useAppSelector(getThemeLookup);

  const sharedInputProps = {
    InputProps: {
      style: {
        //textfield text filled colors
        color: p('textColor', ['color']).color,
      },
    },
    InputLabelProps: {
      style: {
        //textfield label - placeholder colors
        color: p('labelColor', ['color']).color,
      },
    },
  };

  const useStyles = makeStyles(() => ({
    formControlLabel: {
      color: p('checkboxLabelColor', ['color']).color,
    },
    caretIcon: {
      color: `${p('caretColor', ['color']).color} !important`,
    },
    dropdown: {
      '& .MuiSelect-select': {
        //selected menu item color
        color: p('textColor', ['color']).color,
      },
    },
    datePickerActive: {
      '& .MuiInputBase-root': {
        color: p('textColor', ['color']).color,
      },
    },
    datePickerEmpty: {
      '& .MuiInputBase-root': {
        color: p('materialDatePickerPlaceholderColor', ['color']).color,
      },
    },
    datePickerErrors: {
      '& .MuiInputBase-root': {
        color: p('textColor', ['color']).color,
      },
    },
    errors: {
      '& .MuiOutlinedInput-root': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${p('materialTextFieldErrorBorder', ['color']).color} !important`,
        },
      },
    },
    root: {
      '& ::-webkit-calendar-picker-indicator': {
        filter: `invert(${p('fieldIcons', ['color']).color})`,
      },
      //label - placeholder background color
      '& .MuiFormLabel-root': {
        backgroundColor: p('labelBackgroundColor', ['color']).color,
        borderRadius: 5,
        paddingLeft: 5,
        paddingRight: 5,
      },

      //text field errors
      '&  .MuiFormHelperText-root.Mui-error': {
        backgroundColor: p('helperBackground', ['color']).color,
        color: p('helperColor', ['color']).color,
      },
      // focused color for textfield with variant='outlined'
      '& .MuiOutlinedInput-root': {
        //textfield background color
        background: p('fieldBackground', ['color']).color,

        //text field border unfocused
        '& fieldset': {
          borderColor: p('fieldDefaultBorder', ['color']).color,
        },
        //text field border focused
        '&.Mui-focused fieldset': {
          borderColor: p('fieldBorderFocus', ['color']).color,
        },
        //text field border hover
        '&:hover fieldset': {
          borderColor: p('fieldHoverBorderColor', ['color']).color,
        },
      },
      //checkbox
      '&$checked': {
        color: p('checkboxCheckedColor', ['color']).color,
      },
    },

    checked: {},
  }));

  const muiStyles = useStyles();
  const formik = useFormikContext<FormikFields>();

  const { errors, setFieldTouched, touched, values } = formik;

  return (
    <TextField
      size="small"
      variant="outlined"
      value={values[name as keyof FormikFields] || ''}
      fullWidth
      id={id ? id : name}
      name={name}
      type={type}
      label={label}
      disabled={disabled ? disabled : false}
      onChange={onChange}
      required={required}
      style={disableStyles ? {} : styles.inputField}
      error={touched[name as keyof FormikFields] && Boolean(errors[name as keyof FormikFields])}
      helperText={touched[name as keyof FormikFields] ? errors[name as keyof FormikFields] : ''}
      InputProps={sharedInputProps.InputProps}
      InputLabelProps={sharedInputProps.InputLabelProps}
      className={
        errors[name as keyof FormikFields]
          ? muiStyles.root + ' ' + muiStyles.errors
          : muiStyles.root
      }
      onBlur={() => setFieldTouched(name)}
    />
  );
};

export default FormTextField;

const styles: Styles = {
  inputField: {
    marginBottom: 10,
    marginTop: 10,
  },
};
