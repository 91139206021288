import Register from '../components/Register';
import React from 'react';
import LoyaltyTitle from '../components/LoyaltyTitle';

export const SIGN_UP_ROUTE = '/sign-up';

const SignUpScreen = () => {
  return (
    <div>
      <LoyaltyTitle title={'title.modal.memberRegister'} />
      <Register />
    </div>
  );
};

export default SignUpScreen;
