import {
  SALE_STATUSES_MAP,
  PICKED_UP_BY_DRIVER,
  DELIVERED,
} from '../../constants';

import { safeString, dollarsToCents } from '../misc';

import processReceiptItem from './processReceiptItem';

const processId = (a?: number) => (a ? String(a) : undefined);

export default function processSale(rawSale: RawSale): Sale {
  // the additional delivery statuses are not in the same place
  // this code corrects that
  const deliveryStatus = safeString(rawSale.DeliveryInfo?.DeliveryStatus);

  let status = safeString(rawSale.Status);

  if ([PICKED_UP_BY_DRIVER, DELIVERED].includes(deliveryStatus)) {
    status = deliveryStatus;
  }

  return {
    status: SALE_STATUSES_MAP[status] as number,
    folioId: processId(rawSale.FolioID),
    transactionId: processId(rawSale.TransactionID || rawSale.ID),
    orderId: processId(rawSale.OrderID),
    headerId: processId(rawSale.HeaderID),
    pickupTime: rawSale.PickupTime,
    inError: Boolean(rawSale.InError),
    deliveryTrackingUrl: rawSale?.DeliveryInfo?.DeliveryTrackingUrl,
    estimatedDeliveryTime:
      rawSale?.DeliveryInfo?.EstimatedDeliveryTime ||
      rawSale?.EstimatedDeliveryTime,
    items: (rawSale.Items || []).map(processReceiptItem),
    total: rawSale.Total != null ? dollarsToCents(rawSale.Total) : undefined,
    receiptType: rawSale.PrinterSettings?.KioskPrintReceiptType,
  };
}

// example sale details:

// "Address": {},
// "DeliveryTime": null,
// "FolioID": 5,
// "ID": 453,
// "Items": [
//     {
//         "Ingredients": [
//             {
//                 "Ingredients": [],
//                 "Item": "GF Wrap",
//                 "PLUCode": 151,
//                 "Price": 1.0,
//                 "Qty": 1.0,
//                 "RedeemPoints": 0.0
//             },
//             {
//                 "Ingredients": [],
//                 "Item": "#Chicken GF",
//                 "PLUCode": 114,
//                 "Price": 0.0,
//                 "Qty": 1.0,
//                 "RedeemPoints": 0.0
//             },
//             {
//                 "Ingredients": [

//                 ],
//                 "Item": "Make It A Meal",
//                 "PLUCode": 109,
//                 "Price": 0.0,
//                 "Qty": 1.0,
//                 "RedeemPoints": 0.0
//             },
//                                             {
//                         "Ingredients": [],
//                         "Item": "Sweet Potat REG",
//                         "PLUCode": 951,
//                         "Price": 5.4,
//                         "Qty": 1.0,
//                         "RedeemPoints": 0.0
//                     },
//                     {
//                         "Ingredients": [],
//                         "Item": "Cool Ridge",
//                         "PLUCode": 788,
//                         "Price": 3.2,
//                         "Qty": 1.0,
//                         "RedeemPoints": 0.0
//                     }
//         ],
//         "Item": "Americn Drm CHK",
//         "PLUCode": 180,
//         "Price": 12.9,
//         "Qty": 1.0,
//         "RedeemPoints": 0.0
//     }
// ],
// "OrderDate": "2020-10-30T13:54:16",
// "OrderName": "John Smith",
// "PaymentType": null,
// "PaymentTypeName": null,
// "PickupTime": "2020-10-30T14:04:14",
// "PosSaleID": null,
// "SaleType": 102,
// "Status": "Order Submitted",
// "StoreID": 4,
// "TableNo": null,
// "Total": 22.5
