import { createSelector } from 'reselect';
import moment from 'moment';

import getCurrentOrder from './getCurrentOrder';
import { ASAP_TIME } from '../constants';

export default createSelector([getCurrentOrder], currentOrder => {
  const { pickupTime } = currentOrder;

  return pickupTime && pickupTime !== ASAP_TIME
    ? moment(pickupTime).format()
    : undefined;
});
