import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import lodash from 'lodash';

import * as topUpMemberMoney from '../../actionCreators/flows/topUpMemberMoney';

import {
  getMerchantConfig,
  getRememberCreditCard,
  getEnableDynamicPaymentGatewayConfig,
} from '../../selectors/config';
import getPaymentGatewayConfig from '../../selectors/getPaymentGatewayConfig';
import getPurchaser from '../../selectors/getPurchaser';

import PaymentHooks from '../../utils/PaymentHooks';
import Api, { FetchParams } from '../../utils/Api';
import { centsToDollarString } from '../../utils/misc';
import { createFlowApprover, makeErrorSerialisable } from '../../utils/sagas';

import { FAILURE_REASON } from '../../constants/failureReasons';
import { GATEWAY_REQUIRES_ACTION } from '../../constants';
import { PAYMENT_METHOD } from '../../constants/paymentMethod';

export const requested = createFlowApprover(topUpMemberMoney);

export function* approved(
  action: ReturnType<typeof topUpMemberMoney.actions.approved>,
) {
  const {
    payload: {
      authenticationMethod = 'member',
      paymentGatewayToken,
      paymentMethod,
      amount,
      memberId,
      paymentGatewayPublicKey,
    },
    meta: { flowId },
  } = action;

  let reason: topUpMemberMoney.FailureReason;
  try {
    let rememberCreditCard =
      (yield select(getRememberCreditCard)) &&
      paymentMethod === PAYMENT_METHOD.CREDIT_CARD;

    let token;
    let lastFour;
    let hookResult: PaymentHookResult = {};

    const paymentGatewayConfig = yield select(getPaymentGatewayConfig);
    const enableDynamicPaymentGatewayConfig = yield select(
      getEnableDynamicPaymentGatewayConfig,
    );

    if (paymentMethod === PAYMENT_METHOD.SAVED_CARD) {
      token = paymentGatewayToken;
    } else {
      const hook = PaymentHooks.get(paymentMethod);

      if (!hook) {
        reason = FAILURE_REASON.MISSING_PAYMENT_HOOK;
        throw new Error('payment hook not set');
      }

      const merchantConfig = yield select(getMerchantConfig);
      const amountString = centsToDollarString(amount);

      const {
        effective: { email, mobile, name, familyName },
      } = (yield select(getPurchaser)) as Purchaser;

      const fullName = `${name || ''} ${familyName || ''}`.trim();

      const applePayItems = [
        {
          label: 'TopUp',
          amount: amountString,
        },
        {
          label: merchantConfig.merchantName,
          amount: amountString,
        },
      ];

      try {
        hookResult = yield call(hook, {
          email: undefined,
          mobile: undefined,
          applePayItems,
          amount,
          paymentGatewayPublicKey,
          paymentGatewayConfig,
          enableDynamicPaymentGatewayConfig,
          name,
          familyName,
          fullName,
          merchantConfig,
        }) as PaymentHookResult;
      } catch (e) {
        if (lodash.get(e, 'code') === 'cancelled') {
          reason = FAILURE_REASON.PAYMENT_CANCELLED;
        } else {
          reason = FAILURE_REASON.PAYMENT_HOOK_FAILED;
        }
        throw e;
      }

      token = hookResult.token;
      lastFour = hookResult.lastFour;
    }

    const body = {
      HCTopupAmount: amount,
      recid_member: memberId,
      PaymentType: 'cc',
      PaymentInfo: {
        ...(paymentMethod === PAYMENT_METHOD.SAVED_CARD
          ? { saved_pg_token: token }
          : { id: token }),
        remember_pg_token: rememberCreditCard || undefined,
        last_four: rememberCreditCard ? lastFour : undefined,
        useIntents: enableDynamicPaymentGatewayConfig,
        paymentMethodType: paymentMethod,
      },
    };

    const params: FetchParams = {
      path: '/api/v1/sale',
      method: 'POST',
      body,
      applicationErrorAllowed: true,
    };

    let rawSale: RawSale | undefined;

    try {
      let response = yield call(Api.fetch, params, authenticationMethod);

      console.log(response);

      
      if (response.error_code === 206) {
        // payment gateway says action is required

        const hook: any = PaymentHooks.get(GATEWAY_REQUIRES_ACTION);

        if (!hook) {
          throw new Error('payment hook not set');
        }

        const paymentGatewayConfig = yield select(getPaymentGatewayConfig);
        const merchantConfig = yield select(getMerchantConfig);

        const hookResult = yield call(hook, {
          serverData: response.extra_info,
          paymentGatewayConfig,
          paymentGatewayMethod: paymentMethod,
          merchantConfig,
        });

        if (!hookResult.success) {
          reason = FAILURE_REASON.PAYMENT_AUTHENTICATION_FAILED;

          throw new Error(
            lodash.get(hookResult, 'error.message') || 'required action failed',
          );
        }

        // resubmit sale with proof of action

        const resubmitSaleParams: FetchParams = {
          path: {
            trusted: '/api/v1/authsale2/finalise',
            member: '/api/v1/sale/finalise',
            none: '/api/v1/sale/finalise/nonmember',
          }[authenticationMethod],
          method: 'POST',
          body: {
            ...hookResult.data,
          },
        };

        response = yield call(
          Api.fetch,
          resubmitSaleParams,
          authenticationMethod,
        );
      } else if (!response.success) {
        // TODO: this may need a bit of filtering in the future if the error message is too terse or technical but
        // its probably better than a catch all  message
        throw new Error(response.error);
      }
      rawSale = response.data;
    } catch (e) {
      reason = FAILURE_REASON.TOP_UP_FAILED;
      throw e;
    }

    yield put(
      topUpMemberMoney.actions.succeeded(
        {
          paymentMethod: paymentMethod,
          paymentGatewayClientSecret:
            rawSale?.paymentData?.payment_intent_client_secret,
        },
        flowId,
      ),
    );
  } catch (e) {
    yield put(
      topUpMemberMoney.actions.failed(
        { error: makeErrorSerialisable(e), reason },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(topUpMemberMoney.events.REQUESTED, requested),
    takeEvery(topUpMemberMoney.events.APPROVED, approved),
  ]);
}
