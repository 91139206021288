import moment, { Moment } from 'moment';

export default function calculateFutureDatedOrderingWindow(
  pickupTime: string,
  location: POSLocation | POSLocationShort | undefined,
  averageOrderWaitTime: number = 0,
  openingPadding: number = 0,
  closingPadding: number = 0,
  minuteStep: number,
): {
  earliestTime: string;
  latestTime: string;
  openingSession?: { start: string; end: string }[];
} {
  const {
    closingTime,
    openingTime,
    tradingHours,
    customHours,
    holidayHours,
    utcOffset,
  } = location as POSLocation;
  const utc = utcOffset < 0 ? '-' : '+' + (utcOffset / 60 / 60).toString();
  let earliestTime = moment(openingTime)
      .add(openingPadding, 'minute')
      .add(averageOrderWaitTime, 'minute')
      .toISOString(),
    latestTime = moment(closingTime)
      .subtract(closingPadding, 'minute')
      .subtract(averageOrderWaitTime, 'minute')
      .toISOString();

  let result = customHoursValidate(customHours, moment(pickupTime), utc);

  if (result) {
    return {
      earliestTime: result.earliestTime
        .add(openingPadding, 'minute')
        .add(averageOrderWaitTime, 'minute')
        .toISOString(),
      latestTime: result.latestTime
        .subtract(closingPadding, 'minute')
        .subtract(averageOrderWaitTime, 'minute')
        .toISOString(),
    };
  }

  result = holidayHoursValidate(
    tradingHours,
    holidayHours,
    moment(pickupTime),
    utc,
  );

  if (result) {
    return {
      earliestTime: result.earliestTime
        .add(openingPadding, 'minute')
        .add(averageOrderWaitTime, 'minute')
        .toISOString(),
      latestTime: result.latestTime
        .subtract(closingPadding, 'minute')
        .subtract(averageOrderWaitTime, 'minute')
        .toISOString(),
    };
  }
  //Mon - Sun opening hours
  const openingDay = moment(pickupTime).format('dddd');
  const tTradingHours = tradingHours.find(e => e.name === openingDay);

  //selected date opening and closing time
  const py = moment.utc(pickupTime).year();
  const pm = moment.utc(pickupTime).month();
  const pd = moment.utc(pickupTime).date();

  //close all day
  if (!tTradingHours || tTradingHours.closed) {
    return {
      earliestTime: moment([py, pm, pd, 0, 0]).toISOString(),
      latestTime: moment([py, pm, pd, 0, 0]).toISOString(),
    };
  }
  const oh = tTradingHours.openingTime.split(':')[0];
  const om = tTradingHours.openingTime.split(':')[1];

  const ch = tTradingHours.closingTime.split(':')[0];
  const cm = tTradingHours.closingTime.split(':')[1];

  const ot = generateLocalMomentTime(py, pm, pd, oh, om, utcOffset);
  const ct = generateLocalMomentTime(py, pm, pd, ch, cm, utcOffset);

  //yesterday opening hours
  const yesterday = ot.clone().subtract(1, 'days');

  const yTradingHours = tradingHours.find(
    e => e.name === yesterday.format('dddd'),
  )!;

  let yesterdayClosingHour = yTradingHours.closingTime;
  let yesterdayOpeningHour = yTradingHours.openingTime;

  const yy = moment(yesterday).year();
  const ym = moment(yesterday).month();
  const yd = moment(yesterday).date();
  let ych = yesterdayClosingHour.split(':')[0]; //yesterday closing hour
  let ycm = yesterdayClosingHour.split(':')[1]; //yesterday closing minute
  let yoh = yesterdayOpeningHour.split(':')[0]; //yesterday opening hour
  let yom = yesterdayOpeningHour.split(':')[1]; //yesterday opening minute
  //yesterday opening hours
  result = customHoursValidate(customHours, yesterday, utc);
  if (result) {
    const { earliestTime, latestTime } = result;
    ych = latestTime.hour().toString();
    ycm = latestTime.minute().toString();
    yoh = earliestTime.hour().toString();
    yom = earliestTime.minute().toString();
  } else {
    const result = holidayHoursValidate(
      tradingHours,
      holidayHours,
      yesterday,
      utc,
    );
    if (result) {
      const { earliestTime, latestTime } = result;
      ych = latestTime.hour().toString();
      ycm = latestTime.minute().toString();
      yoh = earliestTime.hour().toString();
      yom = earliestTime.minute().toString();
    }
  }

  const yct =
    Number(yoh + yom) > Number(ych + ycm)
      ? generateLocalMomentTime(py, pm, pd, ych, ycm, utcOffset)
      : generateLocalMomentTime(yy, ym, yd, ych, ycm, utcOffset); //yesterday closing time

  const openingSession = []; //multi opening sessiong

  if (Number(yoh + yom) > Number(ych + ycm)) {
    //yesterday opening time is later than yesterday closing time.
    if (
      yct.diff(moment([py, pm, pd]), 'minute') < minuteStep ||
      yct > ot ||
      ot.diff(yct, 'minute') < minuteStep
    ) {
      //yesterday closing time will not affect todays opening time
      earliestTime = ot
        .add(openingPadding, 'minute')
        .add(averageOrderWaitTime, 'minute')
        .toISOString();
      latestTime = (
        ot > ct
          ? generateLocalMomentTime(py, pm, pd, '23', '59', utcOffset)
          : ct
      )
        .subtract(closingPadding, 'minute')
        .subtract(averageOrderWaitTime, 'minute')
        .toISOString();
    } else {
      openingSession.push({
        start: generateLocalMomentTime(py, pm, pd, '00', '00', utcOffset)
          .add(openingPadding, 'minute')
          .add(averageOrderWaitTime, 'minute')
          .toISOString(),
        end: yct
          .subtract(closingPadding, 'minute')
          .subtract(averageOrderWaitTime, 'minute')
          .toISOString(),
      });
      openingSession.push({
        start: ot
          .add(openingPadding, 'minute')
          .add(averageOrderWaitTime, 'minute')
          .toISOString(),
        end: (ot > ct
          ? generateLocalMomentTime(py, pm, pd, '23', '59', utcOffset)
          : ct
        )
          .subtract(closingPadding, 'minute')
          .subtract(averageOrderWaitTime, 'minute')
          .toISOString(),
      });

      earliestTime = openingSession[0].start;
      latestTime = openingSession[1].end;
    }
  } else {
    earliestTime = ot
      .add(openingPadding, 'minute')
      .add(averageOrderWaitTime, 'minute')
      .toISOString();
    latestTime = (
      ot > ct ? generateLocalMomentTime(py, pm, pd, '23', '59', utcOffset) : ct
    )
      .subtract(closingPadding, 'minute')
      .subtract(averageOrderWaitTime, 'minute')
      .toISOString();
  }

  return {
    earliestTime,
    latestTime,
    openingSession: openingSession.length > 0 ? openingSession : undefined,
  };
}

//custom hours settings
const customHoursValidate = (
  customHours: Record<string, CustomHours> | undefined,
  date: Moment,
  utc: string,
): { earliestTime: Moment; latestTime: Moment } | undefined => {
  if (customHours && Object.keys(customHours).length > 0) {
    for (const [key, value] of Object.entries(customHours)) {
      if (moment(date).diff(moment(key), 'days') === 0) {
        const earliestTime = moment(
          `${key}T` + (value.ot ? value.ot : `00:00`) + utc,
        );
        const latestTime = moment(
          `${key}T` + (value.ct ? value.ct : `00:00`) + utc,
        );
        if (!value.closed) {
          return {
            earliestTime,
            latestTime,
          };
        } else {
          return {
            earliestTime,
            latestTime: earliestTime,
          };
        }
      }
    }
  }
  return undefined;
};

//holiday hours settings
const holidayHoursValidate = (
  tradingHours: TradingHours[],
  holidayHours: HolidayHours[] | undefined,
  date: Moment,
  utc: string,
): { earliestTime: Moment; latestTime: Moment } | undefined => {
  if (holidayHours && holidayHours.length > 0) {
    for (let i = 0; i < holidayHours.length; i++) {
      const ho = holidayHours[i].Date;
      const d = moment().year() + '-' + ho.slice(2) + '-' + ho.slice(0, 2);

      if (moment(date).dayOfYear() === moment(d).dayOfYear()) {
        //looking for publick opening hours
        const th = tradingHours.find(e => e.name === 'P. Holidays');

        if (th) {
          const earliestTime = moment(
            `${d}T` + (th.openingTime ? th.openingTime : '00:00') + utc,
          );
          const latestTime = moment(
            `${d}T` + (th.closingTime ? th.closingTime : '00:00') + utc,
          );
          if (!th.closed) {
            return {
              earliestTime,
              latestTime,
            };
          } else {
            return {
              earliestTime,
              latestTime: earliestTime,
            };
          }
        }
      }
    }
  }
};

export const generateLocalMomentTime = (
  year: number,
  month: number,
  day: number,
  hour: string,
  min: string,
  utc: number,
): Moment => {
  const utcOffset =
    utc < 0
      ? '-'
      : '+' +
        (utc / 60 / 60).toLocaleString(undefined, { minimumIntegerDigits: 2 });
  const time = `${year}-${(month + 1).toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  })}-${day.toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  })}T${hour}:${min}${utcOffset}`;
  return moment(time);
};
