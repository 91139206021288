import React, { useEffect, useState, useMemo } from 'react';
import lodash from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { OrderingConstants, OrderingSelectors } from 'polygon-ordering';

import { generateMomentsInRangeFDO as generateMomentsInRange } from '../utils/generateMomentsInRange';
import combineStyles from '../utils/combineStyles';
import { TEXT_PROPERTIES, SELECT_PROPERTIES } from '../utils/theme';
import { useAppSelector } from '../app/hooks';
import Text from './Text';
import getThemeLookup from '../selectors/getThemeLookup';
import pickupTime from 'libs/polygon-ordering/src/reducers/currentOrder/pickupTime';

const { getLocation } = OrderingSelectors;
const { ASAP_TIME } = OrderingConstants;
const REFRESH_INTERVAL_DURATION = 1000 * 60; // 1 minute

interface IProps {
  themeKey: string;
  lockASAP?: boolean;
  readonly?: boolean;
  minuteStep?: number;
  timePadding?: number;
  showTimeInErrorMessage?: boolean;
  reducedWidth?: number;
  value?: string;
  earliestTime?: string;
  latestTime?: string;
  timeInErrorMessage?: string;
  suppressEstimate?: boolean;
  setValue: (value: null | string) => void;
  openingSession?: { [key: string]: string }[];
}

const DateTimeSelector: React.FC<IProps> = ({
  themeKey,
  reducedWidth,
  minuteStep = 5,
  timePadding = 0,
  earliestTime,
  latestTime,
  value: valueProp,
  readonly,
  lockASAP,
  showTimeInErrorMessage,
  timeInErrorMessage,
  suppressEstimate,
  setValue,
  openingSession,
}) => {
  const p = useAppSelector(getThemeLookup);
  const { t } = useTranslation();
  const [, forceUpdate] = useState({});
  const { timeZone } = useAppSelector(getLocation) as POSLocation;
  useEffect(() => {
    const interval = setInterval(() => forceUpdate({}), REFRESH_INTERVAL_DURATION);
    return () => {
      clearInterval(interval);
    };
  }, []);

  let currentValue = valueProp;

  if (!currentValue || currentValue.toUpperCase() === ASAP_TIME || lockASAP) {
    currentValue = undefined;
  }

  const selectedDate = {
    y: moment(valueProp).year(),
    M: moment(valueProp).month(),
    d: moment(valueProp).date(),
  };

  const currentValueMoment = moment(currentValue);

  const earliestTimeMoment =
    valueProp !== ASAP_TIME
      ? moment({
          ...selectedDate,
          h: moment(earliestTime).hour(),
          m: moment(earliestTime).minute(),
        })
      : moment(earliestTime);

  const nowWithPaddingMoment = moment().add(timePadding, 'minutes');

  const earliestSelectableMoment = lodash.orderBy(
    [earliestTimeMoment, nowWithPaddingMoment],
    lodash.identity,
    'desc',
  )[0];

  const latestSelectableMoment =
    valueProp !== ASAP_TIME
      ? moment({
          ...selectedDate,
          h: moment(latestTime).hour(),
          m: moment(latestTime).minute(),
        })
      : moment(latestTime);

  const currentValueAfterLatestTime =
    moment().dayOfYear() === moment(currentValueMoment).dayOfYear()
      ? currentValueMoment > latestSelectableMoment
      : false;

  const noValidTime = latestSelectableMoment <= earliestSelectableMoment;

  const currentValueBeforeEarliestTime =
    moment().dayOfYear() === moment(currentValueMoment).dayOfYear()
      ? currentValueMoment < earliestSelectableMoment
      : false;

  const times = useMemo(
    () =>
      generateMomentsInRange(
        moment({
          ...selectedDate,
          h: earliestSelectableMoment.hour(),
          m: earliestSelectableMoment.minute(),
        }),
        moment({
          ...selectedDate,
          h: latestSelectableMoment.hour(),
          m: latestSelectableMoment.minute(),
        }),
        minuteStep,
        openingSession,
      ),
    [earliestTime, valueProp, latestTime, timePadding, minuteStep, openingSession],
  );
  useEffect(() => {
    if (times.length > 0) {
      if (!times.map(t => t.toISOString()).includes(value) && value !== ASAP_TIME) {
        setValue(times[0].toISOString());
      }
    }
  }, [times]);

  const estimatedASAP = Intl.DateTimeFormat(undefined, {
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
    timeZone,
  }).format(new Date(earliestSelectableMoment.toISOString()));

  if (currentValueBeforeEarliestTime || currentValueAfterLatestTime || noValidTime) {
    currentValue = undefined;
  }

  const value = currentValue ? moment(currentValue).toISOString() : ASAP_TIME;

  const problemExists = noValidTime || currentValueAfterLatestTime;

  const handleTimeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { target } = e;

    if (!target.value) {
      setValue(null);
    } else {
      const newDate = [
        moment(valueProp).year(),
        moment(valueProp).month(),
        moment(valueProp).date(),
      ];
      const newTime = [moment(target.value).hour(), moment(target.value).minute()];
      const newValue = [...newDate, ...newTime];
      setValue(moment(newValue).toISOString());
    }
  };

  return (
    <div style={problemExists && showTimeInErrorMessage ? styles.mainContainer : undefined}>
      <select
        key={String(value !== ASAP_TIME)} // force a full rerender to prevent warning about mixing padding and paddingLeft/Right
        value={value}
        onChange={handleTimeChange}
        disabled={readonly || lockASAP || noValidTime}
        style={combineStyles(
          { textAlignLast: 'center' },
          p('defaultText', TEXT_PROPERTIES),
          p('input', SELECT_PROPERTIES),
          p(themeKey, SELECT_PROPERTIES),
          { width: 170 },
        )}
      >
        {problemExists ? (
          <option value={ASAP_TIME}>{noValidTime ? t('noValidTime') : t('invalidTime')}</option>
        ) : (
          <>
            {!moment(valueProp).startOf('day').diff(moment().startOf('day')) && (
              <option value={ASAP_TIME}>{`${t('asap')}${
                suppressEstimate ? '' : ` (${estimatedASAP})`
              }`}</option>
            )}

            {times.map(time => {
              const formatted = Intl.DateTimeFormat('en-AU', {
                //@ts-ignore
                timeStyle: 'short',
                timeZone,
              }).format(new Date(time.toISOString())); //time.format('h:mm a');

              return (
                <option key={time.toISOString()} value={time.toISOString()}>
                  {formatted}
                </option>
              );
            })}
          </>
        )}
      </select>

      {problemExists && showTimeInErrorMessage && (
        <Text themeKey="timeInErrorMessage" block style={styles.timeInErrorMessage}>
          {timeInErrorMessage}
        </Text>
      )}
    </div>
  );
};
const styles: Styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  timeInErrorMessage: {
    marginTop: 7,
    textAlign: 'center',
    maxWidth: 130,
  },
};
export default DateTimeSelector;
