import { createSelector } from 'reselect';

import getPayment from './getPayment';
import {
  getRememberCreditCard,
  getEnableDynamicPaymentGatewayConfig,
} from './config';
import getGiftCard from './getGiftCard';
import getMember from './getMember';
import { PAYMENT_METHOD } from '../constants/paymentMethod';

export default createSelector(
  [
    getPayment,
    getRememberCreditCard,
    getGiftCard,
    getMember,
    getEnableDynamicPaymentGatewayConfig,
  ],
  (
    payment,
    rememberCreditCard,
    giftCard,
    member,
    enableDynamicPaymentGatewayConfig,
  ) => {
    if (!payment) {
      return undefined;
    }

    const { subPayments } = payment;

    const payments: any[] = [];

    subPayments.forEach(subPayment => {
      switch (subPayment.method) {
        case PAYMENT_METHOD.FREE: {
          payments.push({
            PaymentType: 'cc',
            Amount: subPayment.amount,
          });
          break;
        }
        case PAYMENT_METHOD.PAY_LATER: {
          payments.push({
            PaymentType: 'pt',
            Amount: subPayment.amount,
          });
          break;
        }
        case PAYMENT_METHOD.MEMBER_MONEY: {
          payments.push({
            PaymentType: 'hc',
            Amount: subPayment.amount,
          });
          break;
        }
        case PAYMENT_METHOD.MEMBER_REWARDS:
        case PAYMENT_METHOD.MEMBER_POINTS: {
          payments.push({
            PaymentType: 'lc',
            Amount: subPayment.amount,
          });
          break;
        }
        case PAYMENT_METHOD.EFTPOS: {
          payments.push({
            PrintData: subPayment.receiptText,
            PaymentType: 'cc',
            Amount: subPayment.amount,
            PaymentInfo: {
              CardType: subPayment.cardType,
              ReferenceNumber: subPayment.referenceNumber,
              AuthorisationCode: subPayment.authorisationCode,
            },
          });
          break;
        }
        case PAYMENT_METHOD.SAVED_CARD: {
          payments.push({
            PaymentType: 'cc',
            Amount: subPayment.amount,
            PaymentInfo: {
              saved_pg_token: subPayment.token,
              useIntents: enableDynamicPaymentGatewayConfig,
            },
          });
          break;
        }
        case PAYMENT_METHOD.CREDIT_CARD: {
          payments.push({
            PaymentType: 'cc',
            Amount: subPayment.amount,
            PaymentInfo: {
              id: subPayment.token,
              remember_pg_token: member ? rememberCreditCard : false,
              last_four: subPayment.lastFour,
              useIntents: enableDynamicPaymentGatewayConfig,
              paymentMethodType: subPayment.method,
            },
          });
          break;
        }
        case PAYMENT_METHOD.GOOGLE_PAY:
        case PAYMENT_METHOD.APPLE_PAY: {
          payments.push({
            PaymentType: 'cc',
            Amount: subPayment.amount,
            PaymentInfo: {
              id: subPayment.token,
              useIntents: enableDynamicPaymentGatewayConfig,
              paymentMethodType: subPayment.method,
            },
          });
          break;
        }
        case PAYMENT_METHOD.ALIPAY: {
          payments.push({
            PaymentType: 'cc',
            Amount: subPayment.amount,
            PaymentInfo: {
              id: subPayment.token,
              paymentMethodType: subPayment.method,
              useIntents: enableDynamicPaymentGatewayConfig,
            },
          });
          break;
        }
        case PAYMENT_METHOD.GIFT_CARD: {
          payments.push({
            PaymentType: 'gc',
            Amount: subPayment.amount,
            GCMemberNo: giftCard.number,
            GCVerifyPIN: giftCard.pin,
          });
          break;
        }
        default:
          return undefined;
      }
    });

    return payments;
  },
);
