import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../app/hooks';
import SuperPaymentForm from '../components/SuperPaymentForm';
import PaymentProvider from '../components/PaymentProvider';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import PurchaseGiftCardOptions from './PurchaseGiftCardOptions';
import Text from './Text';
import NonMemberGiftCardFields from './NonMemberGiftCardFields';
import { OrderingSelectors, OrderingHooks } from 'polygon-ordering';
import FormTextField from '../components/FormTextField';
import GiftCardDeliveryDetails from '../components/GiftCardDeliveryDetails';
import moment from 'moment';

const { getMember } = OrderingSelectors;
const { useFormattedCurrency } = OrderingHooks;

const PurchaseGiftCardForOthers: React.FC = () => {
  const { t } = useTranslation();
  const member = useAppSelector(getMember);

  const isSameOrAfter = (startTime: any, endTime: any) => {
    return moment(startTime, 'HH:mm').isSameOrAfter(moment(endTime, 'HH:mm'));
  };

  const memberSchema = {
    Amount: Yup.number().required('Required'),
    RecipientName: Yup.string().required('Required'),
    RecipientSurname: Yup.string().required('Required'),
    RecipientEmail: Yup.string().email().required('Required'),
    delivery: Yup.boolean(),
    SendTime: Yup.date().when('delivery', {
      is: true,
      then: Yup.date().test('SendTime', 'Date and time must be after now', (value: any) => {
        return isSameOrAfter(value, new Date());
      }),
    }),
  };

  const nonMemberSchema = {
    RecipientName: Yup.string().required('Required'),
    RecipientSurname: Yup.string().required('Required'),
    RecipientEmail: Yup.string().email().required('Required'),
    FirstName: Yup.string().required('Required'),
    LastName: Yup.string().required('Required'),
    Email: Yup.string().email().required('Required'),
    ConfirmEmail: Yup.string().email().required('Required'),
    Amount: Yup.number().required('Required'),
    delivery: Yup.boolean(),
    SendTime: Yup.date().when('delivery', {
      is: true,
      then: Yup.date().test('SendTime', 'Date and time must be after now', (value: any) => {
        return isSameOrAfter(value, new Date());
      }),
    }),
  };
  const memberValidationSchema = Yup.object().shape(memberSchema);
  const nonMemberValidationSchema = Yup.object().shape(nonMemberSchema);

  const handleFormSubmit = () => {};

  const memberInitialValues = {
    Amount: '',
    RecipientName: '',
    RecipientSurname: '',
    RecipientEmail: '',
    Memo: '',
    SendTime: '',
    timezone: 'Australia/Melbourne',
    delivery: false,
  };
  const nonMemberInitialValues = {
    Amount: '',
    FirstName: '',
    LastName: '',
    Email: '',
    ConfirmEmail: '',
    RecipientName: '',
    RecipientSurname: '',
    RecipientEmail: '',
    Memo: '',
    SendTime: '',
    timezone: 'Australia/Melbourne',
    delivery: false,
  };

  const fields = [
    {
      name: 'RecipientName',
      type: 'text',
      label: t('placeholder.firstName'),
    },
    {
      name: 'RecipientSurname',
      type: 'text',
      label: t('placeholder.lastName'),
    },

    {
      name: 'RecipientEmail',
      type: 'email',
      label: t('placeholder.email'),
    },
    {
      name: 'Memo',
      type: 'text',
      label: t('placeholder.message'),
    },
  ];

  return (
    <PaymentProvider>
      <Formik
        validationSchema={member ? memberValidationSchema : nonMemberValidationSchema}
        initialValues={member ? memberInitialValues : nonMemberInitialValues}
        onSubmit={handleFormSubmit}
      >
        {({ handleSubmit, handleChange }) => {
          return (
            <Form onSubmit={handleSubmit}>
              {!member && <NonMemberGiftCardFields />}
              <Text
                value={t('recipientDetails')}
                style={{ fontSize: 15 }}
                themeKey="recipientDetails"
              />
              {fields.map(field => (
                <FormTextField
                  key={field.name}
                  type={field.type}
                  name={field.name}
                  label={field.label}
                  onChange={handleChange}
                />
              ))}

              <GiftCardDeliveryDetails />

              <PurchaseGiftCardOptions formatCurrency={useFormattedCurrency} />
              <SuperPaymentForm
                route={
                  member ? 'memberPurchaseGiftCardForOthers' : 'nonMemberPurchaseGiftCardForOthers'
                }
                notRequired={['Memo', 'SendTime']}
              />
            </Form>
          );
        }}
      </Formik>
    </PaymentProvider>
  );
};

export default PurchaseGiftCardForOthers;
