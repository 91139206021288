import { call, put } from 'redux-saga/effects';

import { OrderingConstants, OrderingOperations, requestAndWaitForFlow } from 'polygon-ordering';

import EVENTS from '../constants/events';

import { logEvent } from '../utils/analytics';
import debug from '../utils/debug';

import history from '../history';

//slices
import { setLocationWhitelist } from '../slices/locationWhitelist';
import { setHandleURLParamsInProgress } from '../slices/handleURLParamsInProgress';
import { setCurrentMenuBrandId } from '../slices/currentMenuBrandId';

import adjustOrder from './adjustOrder';
import adjustSaleType from './adjustSaleType';
import adjustLocation from './adjustLocation';

const { SALE_TYPES } = OrderingConstants;
const {
  setTable,
  startNewOrder,
  // applyShadowOrder,
  // setShadowNotes,
  // setShadowCart,
  // addToShadowCart,
} = OrderingOperations;

const getSearchParam = (searchParams, keys = [], isInt) => {
  let keysArray = keys;

  if (!Array.isArray(keysArray)) {
    keysArray = [keysArray];
  }

  let result;

  keysArray.some(key => {
    result = searchParams.get(key);
    return result != null;
  });

  return isInt && result ? parseInt(result) : result;
};

const LOCATION_ID_ALIASES = [
  'locationId',
  'locationid',
  'location',
  'location_id',
  'store',
  'storeid',
  'storeId',
  'store_id',
];

const SALE_TYPE_ALIASES = [
  'saleType',
  'saletype',
  'sale_type',
  'order_type',
  'ordertype',
  'orderType',
];

const BRAND_OVERRIDE_ALIASES = [
  'brand',
  'brand_id',
]

const TABLE_NUMBER_ALIASES = ['tableNumber', 'tablenumber', 'table_num'];

const LOCATION_WHITELIST_ALIASES = ['locations', 'stores', 'locationIds', 'storeIds'];

export default function* handleURLParams() {
  let dontAttemptMenuUpdate;

  try {
    yield put(setHandleURLParamsInProgress(true));

    const { searchParams } = new URL(window.location.href);

    let preserveOrderParam = getSearchParam(searchParams, ['preserveOrder']);
    const preserveOrder =
      preserveOrderParam === '1' || String(preserveOrderParam).toLowerCase() === 'true';

    let locationId = getSearchParam(searchParams, LOCATION_ID_ALIASES, true);
    let saleType = getSearchParam(searchParams, SALE_TYPE_ALIASES, true);
    let tableNumber = getSearchParam(searchParams, TABLE_NUMBER_ALIASES);
    let brandOverride = getSearchParam(searchParams, BRAND_OVERRIDE_ALIASES)

    let locationWhitelist = (getSearchParam(searchParams, LOCATION_WHITELIST_ALIASES) || '')
      .split(',')
      .filter(id => id !== '');

    const params = {
      locationId,
      saleType,
      tableNumber,
      locationWhitelist,
      preserveOrder,
      brandOverride,
      raw: searchParams.toString(),
    };

    debug('URL params', params);

    if (params.raw) {
      logEvent(EVENTS.LOAD_WITH_URL_PARAMS, { label: params.raw });

      // remove params from URL bar (does not reload)
      // yield call(history.replace);
    }

    const anyPresent = locationId != null || saleType != null || locationWhitelist.length > 0;

    if (anyPresent && !preserveOrder) {
      yield call(requestAndWaitForFlow, startNewOrder, { preserveMember: true });
    }

    if (
      tableNumber != null &&
      (saleType === SALE_TYPES.DINE_IN || saleType === SALE_TYPES.TABLE_ORDER)
    ) {
      yield put(setTable(tableNumber));
    }

    const ignoreLocationId = saleType === SALE_TYPES.DELIVERY;

    if (locationWhitelist.length) {
      yield put(setLocationWhitelist(locationWhitelist));
    } else if (anyPresent) {
      yield put(setLocationWhitelist([]));
    }

    if (saleType != null && (locationId == null || ignoreLocationId)) {
      debug('initiating sale type change');

      yield call(adjustSaleType, { payload: { saleType, locationId } });
    } else if (locationId != null && saleType == null) {
      debug('initiating location change');

      yield call(adjustLocation, { payload: { locationId } });
    } else if (locationId != null && saleType != null) {
      debug('initiating sale type and location change');

      yield call(adjustOrder, { payload: { saleType, locationId } });
    }

    if (locationId != null && brandOverride != undefined) {
      debug("brand overide and location id exists, redirecting to brand from specified location")
      yield put(setCurrentMenuBrandId(brandOverride))
    }

    dontAttemptMenuUpdate = anyPresent;
  } catch (e) {
    const message = 'Issue processing URL params. Please check your URL.';
    console.error(message, { e });
  }

  yield put(setHandleURLParamsInProgress(false));

  return dontAttemptMenuUpdate;
}
