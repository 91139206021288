import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as null | string;

const slice = createSlice({
  name: 'desiredDeliveryTime',
  initialState,
  reducers: {
    setDesiredDeliveryTime(state, action: PayloadAction<string>) {
      const { payload } = action;

      return payload == null ? initialState : payload;
    },
  },
});

export const { setDesiredDeliveryTime } = slice.actions;

export default slice.reducer;
