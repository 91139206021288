import { createSelector } from 'reselect';

// utils
import { getInitialFieldValues } from '../utils/fields';

import getRegisterProfileFields from './getRegisterProfileFields';

export default createSelector([getRegisterProfileFields], registrationFields => {
  const visible = registrationFields?.visible;
  const hidden = registrationFields?.hidden;

  return {
    visible: getInitialFieldValues(visible),
    hidden: getInitialFieldValues(hidden),
  };
});
