import React, { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import delay from '../utils/delay';
import { setCreditCardComplete } from '../slices/creditCardComplete';
import { Elements } from '@stripe/react-stripe-js';

const PaymentWrapper = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useAppDispatch();
  const hqGatewayKey = useAppSelector(state => state.config.hqGatewayKey);
  const [stripe, setStripe] = useState<Stripe | null>(null);

  useEffect(() => {
    dispatch(setCreditCardComplete(false));
    readyStripe();
  }, [hqGatewayKey]);

  const readyStripe = () => {
    if (!hqGatewayKey) {
      return;
    }
    setStripe(null);
    delay(1000)
      .then(() => loadStripe(hqGatewayKey))
      .then(stripe => setStripe(stripe));
  };
  return (
    <div>
      <Elements key={hqGatewayKey} stripe={stripe}>
        {children}
      </Elements>
    </div>
  );
};

export default PaymentWrapper;
