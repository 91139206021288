import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import StandardButton from '../components/StandardButton';
import getProfileInitialValues from '../selectors/getProfileInitialValues';
import getMemberFields from '../selectors/getMemberFields';
import getMemberSchema from '../selectors/getMemberSchema';
import getProfile from '../selectors/getProfile';
import { RedcatApiHandler } from 'polygon-utils';
import {
  enqueueErrorSnackbar,
  enqueueWarningSnackbar,
  enqueueSuccessSnackbar,
} from '../utils/snackbar';
import { Formik, Form, FormikHelpers } from 'formik';
import { OrderingOperations } from 'polygon-ordering';
import Text from '../components/Text';
import SendVerificationButton from '../components/SendVerificationButton';

const { removeMember } = OrderingOperations;

export const DELETE_ACCOUNT_ROUTE = '/delete-account';

const DeleteAccountScreen = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const initialVal = useAppSelector(getProfileInitialValues);
  const memberFields = useAppSelector(getMemberFields);
  const memberSchema = useAppSelector(getMemberSchema);
  const profile = useAppSelector(getProfile);

  // should be the same endpoint to update the password
  const submit = (params = {}) => {
    const path = '/api/v1/profile/remove';
    RedcatApiHandler.authorisedFetch({
      path,
      method: 'POST',
      body: params,
    })
      .then(response => {
        //@ts-ignore
        // if (response.token) {
        //   dispatch(syncMember());
        // }

        if (response.success) {
          dispatch(removeMember({}));
          enqueueWarningSnackbar('Account Deleted! Signed Out...');

          //   setSubmitting(false);
          //   resetForm({ values });
        }
      })
      .catch(err => {
        enqueueErrorSnackbar(err);
      });
  };

  return (
    <div>
      {!profile?.verified ? (
        <div style={{ textAlign: 'center', padding: '20px 0' }}>
          <Text value={t('deleteAccountIfNotVerified')} style={{ fontSize: 14 }} />
          <SendVerificationButton containerStyle={{ paddingTop: 20 }} />
        </div>
      ) : (
        <Formik
          validationSchema={memberSchema.visible}
          initialValues={initialVal.visible}
          onSubmit={submit}
          enableReinitialize={true}
        >
          {({ handleSubmit, isSubmitting, isValid, dirty }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <div className="position-relative" style={{ marginTop: 20 }}>
                  <Text
                    value={t('deleteAccountDetails')}
                    style={{ textAlign: 'left', fontSize: 16 }}
                  />
                  <div style={{ paddingTop: 50, margin: '0 50px' }}>
                    <StandardButton
                      containerStyle={styles.button}
                      themeKey="loyaltyButton"
                      label={t('button.deleteAccount')}
                      onClick={handleSubmit}
                      disabled={false}
                      showSpinner={isSubmitting}
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

const styles: Styles = {
  button: {
    borderRadius: 5,
    height: 40,
  },
};

export default DeleteAccountScreen;
