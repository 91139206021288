import {
  call,
  put,
  takeEvery,
  all,
  select,
  putResolve,
} from 'redux-saga/effects';
import lodash from 'lodash';

import * as finaliseOrder from '../../actionCreators/flows/finaliseOrder';
import * as recordPayment from '../../actionCreators/flows/recordPayment';
import * as submitSale from '../../actionCreators/flows/submitSale';

import { setPayment } from '../../actionCreators/currentOrder';

import getPayment from '../../selectors/getPayment';
import { getAttempts } from '../../selectors/config';

import { FAILURE_REASON } from '../../constants/failureReasons';

import {
  requestAndWaitForFlow,
  createFlowApprover,
  makeErrorSerialisable,
} from '../../utils/sagas';

import { PAYMENT_METHOD } from '../../constants/paymentMethod';

export const requested = createFlowApprover(finaliseOrder);

export function* approved(
  action: ReturnType<typeof finaliseOrder.actions.approved>,
) {
  const {
    payload: {
      subPayments,
      authenticationMethod,
      paymentGatewayToken,
      paymentGatewayPublicKey,
      receiptType,
    },
    meta: { flowId },
  } = action;

  console.log(
    subPayments,
    authenticationMethod,
    paymentGatewayToken,
    paymentGatewayPublicKey,
    receiptType,
  );

  let reason: finaliseOrder.FailureReason;
  let charged;
  let chargeStarted;
  let paymentError;

  try {
    let payment: Payment | undefined;

    if (subPayments.length) {
      yield putResolve(setPayment(null));

      const payloads = yield call(
        requestAndWaitForFlow,
        recordPayment,
        {
          subPayments,
          paymentGatewayToken,
          paymentGatewayPublicKey,
        },
        flowId,
      );

      payment = lodash.get(payloads, 'success.payment');
      reason = lodash.get(
        payloads,
        'failure.reason',
        FAILURE_REASON.PAYMENT_FAILED,
      );
    } else {
      payment = yield select(getPayment);
    }

    if (!payment) {
      if (!lodash.find(subPayments, ['method', PAYMENT_METHOD.EFTPOS])) {
        charged = false;
      }
      // TODO: return a better error message
      throw new Error('payment missing, subflow did not finish successfully');
    }

    const attemptsConfig = (yield select(getAttempts)) as AttemptsConfig;
    let attemptsRemaining = attemptsConfig.submitSale;

    let saleDetails;

    // used by apple-pay
    let paymentGatewayClientSecret;

    let errorMessage;

    while (attemptsRemaining) {
      attemptsRemaining -= 1;

      const payloads = yield call(
        requestAndWaitForFlow,
        submitSale,
        { authenticationMethod, receiptType },
        flowId,
      );

      saleDetails = lodash.get(payloads, 'success.saleDetails');

      paymentGatewayClientSecret = lodash.get(
        payloads,
        'success.paymentGatewayClientSecret',
      );

      reason = lodash.get(
        payloads,
        'failure.reason',
        FAILURE_REASON.SALE_FAILED,
      );

      paymentError = lodash.get(payloads, 'failure.error.message');
      charged = lodash.get(payloads, 'failure.charged');
      chargeStarted = lodash.get(payloads, 'failure.chargeStarted');
      errorMessage = lodash.get(payloads, 'failure.error.message');

      if (
        paymentError ===
        'The card’s security code is incorrect. Check the card’s security code or use a different card.'
      ) {
        charged = false;
      }

      if (
        paymentError === 'Unverified members cannot pay with Loyalty Points or Hybrid Cash'
      ) {
        reason = FAILURE_REASON.UNVERIFIED_MEMBER;
      }

      if (saleDetails) {
        break;
      }
    }

    if (!saleDetails) {
      throw new Error(
        errorMessage ||
          'saleDetails missing, subflow did not finish successfully',
      );
    }

    yield put(
      finaliseOrder.actions.succeeded(
        { subPayments, saleDetails, paymentGatewayClientSecret },
        flowId,
      ),
    );
  } catch (e) {
    yield put(
      finaliseOrder.actions.failed(
        {
          error: makeErrorSerialisable(e),
          reason,
          charged,
          chargeStarted,
          subPayments,
        },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(finaliseOrder.events.REQUESTED, requested),
    takeEvery(finaliseOrder.events.APPROVED, approved),
  ]);
}
