import React from 'react';
import { useTranslation } from 'react-i18next';

import { OrderingSelectors } from 'polygon-ordering';
import history from '../history';
import { DESKTOP_MODAL_TYPES } from '../constants';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import Modal from '../components/Modal';
import StandardButton from '../components/StandardButton';
import MemberBalances from '../components/MemberBalances';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import combineStyles from '../utils/combineStyles';
import getThemeLookup from '../selectors/getThemeLookup';
import { setCurrentModal } from '../thunks/setCurrentModal';
import { useLocation } from 'react-router-dom';
import HeaderDropdownButton from '../components/HeaderDropdownButton';
import { getLoyaltyDropdownMenu } from '../utils/getAvailableScreens';

const { getMember } = OrderingSelectors;

export const MEMBER_MODAL_ID = 'MEMBER_MODAL_ID';

const MemberModal = () => {
  const member = useAppSelector(getMember);
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const dispatch = useAppDispatch();
  const p = useAppSelector(getThemeLookup);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const loyaltyMenu = getLoyaltyDropdownMenu();
  const isOnlyLoyaltyEnabled = useAppSelector(state => state.config.enableOnlyLoyalty);

  if (!member) {
    return null;
  }

  const loyaltyScreenOn =
    loyaltyMenu.filter(screen => {
      if (screen.path === pathname) {
        return true;
      } else if (screen.subRoutes) {
        return screen.subRoutes.filter(route => route.path === pathname).length !== 0;
      }
    }).length !== 0;

  return (
    <Modal
      clickBackgroundToClose
      desktopModalType={DESKTOP_MODAL_TYPES.CALLOUT}
      desktopMaxWidth={200}
      desktopMinWidth={200}
      desktopContainerStyle={styles.modalDesktopContainer}
      mobileContainerStyle={styles.modalMobileContainer}
      hideFloatingHeader
    >
      {deviceTypeMobile && (
        <h1
          style={{
            paddingLeft: 20,
            cursor: 'pointer',
            color: p('closeIconColor', ['color']).color,
          }}
          onClick={() => dispatch(setCurrentModal(null))}
        >
          &times;
        </h1>
      )}

      {member && (
        <div>
          <MemberBalances />
        </div>
      )}

      <div>
        {!isOnlyLoyaltyEnabled && loyaltyScreenOn && deviceTypeMobile && (
          <StandardButton
            containerStyle={combineStyles(styles.button, styles.buttonMobile)}
            themeKey="orderOnlineButton"
            label={t('button.orderOnline')}
            onClick={() => {
              history.push('/');
              dispatch(setCurrentModal(null));
            }}
          />
        )}

        <HeaderDropdownButton signoutActive />
      </div>
    </Modal>
  );
};

const styles: Styles = {
  modalDesktopContainer: {
    borderRadius: 2,
  },
  modalMobileContainer: {
    padding: 0,
    minHeight: '100vh',
    paddingBottom: 55,
  },
  buttonMobile: {
    paddingLeft: 40,
  },
  buttonDesktop: {
    paddingLeft: 20,
    borderTop: '1px solid lightgrey',
  },

  button: {
    justifyContent: 'left',
    margin: 0,
  },
};

export default MemberModal;
