import { combineReducers, Reducer, AnyAction } from 'redux';

import apiHandlerReady from './apiHandlerReady';
import attempts from './attempts';
import brands from './brands';
import cardsets from './cardsets';
import choiceSetQuantityDiscount from './choiceSetQuantityDiscount';
import couponOrderingMethod from './couponOrderingMethod';
import eftpos from './eftpos';
import enableDineInNameMode from './enableDineInNameMode';
import enabledPaymentMethods from './enabledPaymentMethods';
import enableGuestOrdering from './enableGuestOrdering';
import enabledSaleTypes from './enabledSaleTypes';
import enableDuplicateSaleProtection from './enableDuplicateSaleProtection';
import enableDynamicPaymentGatewayConfig from './enableDynamicPaymentGatewayConfig';
import enableMultipleDeliveryEstimate from './enableMultipleDeliveryEstimate';
import excludedChoiceSets from './excludedChoiceSets';
import flattenCategories from './flattenCategories';
import freeChoiceOrderingMethod from './freeChoiceOrderingMethod';
import importedGiftCardRegex from './importedGiftCardRegex';
import internationalMobileAllowed from './internationalMobileAllowed';
import loyaltyRequiresVerification from './loyaltyRequiresVerification';
import menuSource from './menuSource';
import merchant from './merchant';
import orderingProvider from './orderingProvider';
import orderingWindowPadding from './orderingWindowPadding';
import paymentEnvironment from './paymentEnvironment';
import printerless from './printerless';
import product from './product';
import rememberCreditCard from './rememberCreditCard';
import requiredGuestPurchaserDetails from './requiredGuestPurchaserDetails';
import requiredPurchaserDetails from './requiredPurchaserDetails';
import saleInputType from './saleInputType';
import showHiddenLocations from './showHiddenLocations';
import thresholdOrderingProviders from './thresholdOrderingProviders';
import enablePayLater from './enablePayLater';
import payLaterRules from './payLaterRules';
import hideNegativeIngredients from './hideNegativeIngredients';
import stockBalanceThresholds from './stockBalanceThresholds';
import stockBalanceIgnoreThreshold from './stockBalanceIgnoreThreshold';
import mobileRegexWhiteList from './mobileRegexWhitelist';
import futureOrderingLimit from './futureOrderingLimit';
import enableFutureOrdering from './enableFutureOrdering';
import futureOrderingLimitCatering from './futureOrderingLimitCatering';
import includeDiscountMinSpend from './includeDiscountMinSpend';
import localiseOrderTimes from './localiseOrderTimes';
import currencySymbol from './currencySymbol';
import orderingCurrency from './orderingCurrency';
import taxName from './taxName';
import countryCode from './countryCode';
import includeCouponDiscountSurcharge from './includeCouponDiscountSurcharge';

// @ts-ignore
const rootReducer: Reducer<Config, AnyAction> = combineReducers({
  futureOrderingLimitCatering,
  mobileRegexWhiteList,
  apiHandlerReady,
  attempts,
  brands,
  cardsets,
  choiceSetQuantityDiscount,
  couponOrderingMethod,
  eftpos,
  enableDineInNameMode,
  enabledPaymentMethods,
  enableGuestOrdering,
  enabledSaleTypes,
  enableDuplicateSaleProtection,
  enableDynamicPaymentGatewayConfig,
  enableMultipleDeliveryEstimate,
  excludedChoiceSets,
  flattenCategories,
  freeChoiceOrderingMethod,
  importedGiftCardRegex,
  internationalMobileAllowed,
  loyaltyRequiresVerification,
  menuSource,
  merchant,
  orderingProvider,
  orderingWindowPadding,
  paymentEnvironment,
  printerless,
  product,
  rememberCreditCard,
  requiredGuestPurchaserDetails,
  requiredPurchaserDetails,
  saleInputType,
  showHiddenLocations,
  thresholdOrderingProviders,
  enablePayLater,
  payLaterRules,
  hideNegativeIngredients,
  stockBalanceThresholds,
  stockBalanceIgnoreThreshold,
  futureOrderingLimit,
  enableFutureOrdering,
  includeDiscountMinSpend,
  localiseOrderTimes,
  currencySymbol,
  orderingCurrency,
  taxName,
  countryCode,
  includeCouponDiscountSurcharge,
});

export default rootReducer;
