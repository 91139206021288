import React, { useEffect, useState, useRef } from 'react';
//@ts-ignore
import postscribe from 'postscribe';
import { useTranslation } from 'react-i18next';

import { RiRefreshLine } from 'react-icons/ri';

import { IN_DEVELOPMENT } from '../constants';

import bootAction from '../actions/boot';
import checkDateAction from '../actions/checkDate';

import getThemeLookup from '../selectors/getThemeLookup';

import combineStyles from '../utils/combineStyles';

import BrandedFavicon from './BrandedFavicon';
import CanaryScreenSize from './CanaryScreenSize';
import CanaryCurrentScreen from './CanaryCurrentScreen';
import DevTools from './DevTools';

import Routes from '../screens/Routes';
import Modals from '../modals/Modals';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import 'bootstrap/dist/css/bootstrap.min.css';
import { setPaymentFormFilled } from '../slices/paymentFormFilled';
import { useLocation } from 'react-router-dom';
import AuthHookSubscriber from './AuthHookSubscriber';
import { Helmet } from 'react-helmet-async';

export function measureHeight() {
  const MYSTERIOUS_BUG_FACTOR = 15;

  const clientHeight = document?.documentElement?.clientHeight;

  // https://github.com/mvasin/react-div-100vh/issues/51
  const buggedMeasurement =
    clientHeight && clientHeight + MYSTERIOUS_BUG_FACTOR === window.innerHeight;

  return buggedMeasurement ? window.innerHeight : clientHeight;
}

// NOTE: in general please do not use hooks in this project. This is a special case that will be removed
export function use100vh() {
  const [height, setHeight] = useState(measureHeight);

  useEffect(function () {
    function setMeasuredHeight() {
      setHeight(measureHeight());
    }

    window.addEventListener('resize', setMeasuredHeight);

    return function () {
      return window.removeEventListener('resize', setMeasuredHeight);
    };
  });

  return height;
}

const MaxHeight: React.FC<React.ReactNode> = ({ children }) => {
  const height = use100vh();

  return <div style={{ height }}>{children}</div>;
};

const App: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const firstSyncComplete = useAppSelector(state => state.firstSyncComplete);
  const p = useAppSelector(getThemeLookup);
  const { pathname } = useLocation();
  const paymentFormFilled = useAppSelector(state => state.paymentFormFilled);
  const scriptInjection = useAppSelector(state => state.config.scriptInjection);
  const metaDescription = t('metaTags.description');
  const myRef = useRef<null | HTMLDivElement>(null);

  const handleURLParamsInProgress = useAppSelector(state => state.handleURLParamsInProgress);
  useEffect(() => {
    dispatch(bootAction());
    const dayCheckInterval = setInterval(() => dispatch(checkDateAction()), 1000 * 60 * 30);
    return () => {
      clearInterval(dayCheckInterval);
    };
  }, []);

  useEffect(() => {
    if (paymentFormFilled) {
      dispatch(setPaymentFormFilled(false));
    }
  }, [pathname]);

  useEffect(() => {
    if (firstSyncComplete) {
      runScriptInjection();
    }
  }, [firstSyncComplete]);

  const runScriptInjection = () => {
    window.requestAnimationFrame(() => {
      const node = myRef.current;
      // node can be null not just undefined
      if (node && scriptInjection) {
        postscribe('#main-container', scriptInjection);
      }
    });
  };

  return (
    <div id="main-container" ref={myRef}>
      <Helmet>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <AuthHookSubscriber />
      <CanaryScreenSize />
      <CanaryCurrentScreen />
      <BrandedFavicon />
      <MaxHeight>
        <div style={combineStyles(styles.app, p('app', ['backgroundColor']))}>
          {firstSyncComplete && (
            <>
              <Routes />
              <Modals />
            </>
          )}

          {(!firstSyncComplete || handleURLParamsInProgress) && (
            <div
              style={combineStyles(
                styles.loadingOverlay,
                p('appLoadingOverlay', ['backgroundColor']),
              )}
            >
              <RiRefreshLine
                className="spinner"
                style={p('appLoadingSpinner', ['fontSize', 'color'])}
              />
            </div>
          )}
        </div>
      </MaxHeight>

      <div id="injected-scripts"></div>

      {IN_DEVELOPMENT && <DevTools />}
    </div>
  );
};

const styles: Styles = {
  app: {
    height: '100%',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'hidden',
  },
  loadingOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 900,
  },
};

export default App;
