import { call, put, select } from 'redux-saga/effects';

import { OrderingConstants, OrderingOperations } from 'polygon-ordering';

import debug from '../utils/debug';

import adjustOrder from './adjustOrder';

import setCurrentModal from '../actions/setCurrentModal';

import getDisplayableSaleTypes from '../selectors/getDisplayableSaleTypes';
import getProfile from '../selectors/getProfile';

import { LOCATION_MODAL_ID } from '../modals/LocationModal';
import { DELIVERY_DETAILS_MODAL_ID } from '../modals/DeliveryDetailsModal';
import { ORDER_TYPE_MODAL_ID } from '../modals/OrderTypeModal';

import { setHandleURLParamsInProgress } from '../slices/handleURLParamsInProgress';

const { SALE_TYPES } = OrderingConstants;
const { setPickupTime } = OrderingOperations;

export default function* adjustSaleType({
  payload: { saleType: saleType, locationId: locationId },
}) {
  debug('adjusting sale type', { saleType });

  // Feat: stop user from ordering if guest ordering is disabled?
  // const profile = yield select(getProfile);
  // if (!profile.verified) {
  //   reason = 'notVerified';
  //   throw new Error('not verified');
  // }

  const displayableSaleTypes = yield select(getDisplayableSaleTypes);

  const previousModalId = displayableSaleTypes.length > 1 ? ORDER_TYPE_MODAL_ID : null;

  yield put(setPickupTime(null));

  if (locationId) {
    if (SALE_TYPES.DELIVERY === saleType) {
      yield put(
        setCurrentModal({
          modalId: DELIVERY_DETAILS_MODAL_ID,
          previousModalId,
        }),
      );
    } else {
      yield put(setHandleURLParamsInProgress(true));

      yield call(adjustOrder, { payload: { saleType: saleType, locationId: locationId } });

      yield put(setHandleURLParamsInProgress(false));
    }
  } else {
    yield put(
      setCurrentModal(
        SALE_TYPES.DELIVERY === saleType
          ? {
              modalId: DELIVERY_DETAILS_MODAL_ID,
              previousModalId,
            }
          : {
              modalId: LOCATION_MODAL_ID,
              previousModalId,
              params: { saleType },
            },
      ),
    );
  }
}
