import { call, put, all } from 'redux-saga/effects';
import moment from 'moment';
import i18next from 'i18next';

import { OrderingOperations, OrderingConstants, requestAndWaitForFlow } from 'polygon-ordering';

import debug from '../utils/debug';

import { setDeliveryEstimateInProgress } from '../slices/deliveryEstimateInProgress';
import { setDesiredDeliveryAddress } from '../slices/desiredDeliveryAddress';
import { setDesiredDeliveryTime } from '../slices/desiredDeliveryTime';
import { setDesiredDeliveryAddressNotFound } from '../slices/desiredDeliveryAddressNotFound';
import { setDeliveryEstimateFailed } from '../slices/deliveryEstimateFailed';

const { updateKeyOrderProperty } = OrderingOperations;

const { SALE_TYPES, FAILURE_REASON, ASAP_TIME } = OrderingConstants;

export default function* performDeliveryEstimate(action) {
  yield put(setDeliveryEstimateInProgress(true));

  try {
    let { deliveryAddress, desiredDeliveryTime: payloadDesiredDeliveryTime } = action.payload;

    let desiredDeliveryTime = payloadDesiredDeliveryTime;

    // do this to prevent "PickupAt value must be set to a time in the future"
    // if the user selects a time, then does a change/reestimate after the time goes into the past
    if (
      desiredDeliveryTime &&
      desiredDeliveryTime !== ASAP_TIME &&
      moment() > moment(desiredDeliveryTime)
    ) {
      desiredDeliveryTime = ASAP_TIME;
    }

    const updateKeyOrderPropertyResult = yield call(requestAndWaitForFlow, updateKeyOrderProperty, {
      autoApply: false,
      saleType: SALE_TYPES.DELIVERY,
      deliveryAddress,
      desiredDeliveryTime,
    });

    if (!updateKeyOrderPropertyResult.succeeded) {
      const {
        reason,
        userReason,
        systemReason,
        matchedAddressString,
      } = updateKeyOrderPropertyResult.failure;

      // TODO: change to snackbar?
      alert(userReason || systemReason || i18next.t('deliveryEstimateFailedMessage', { reason }));

      if (matchedAddressString) {
        yield put(setDesiredDeliveryAddress(matchedAddressString));
      }

      if (reason === FAILURE_REASON.ADDRESS_NOT_FOUND) {
        yield put(setDesiredDeliveryAddressNotFound(true));
      }

      yield put(setDeliveryEstimateFailed(true));
    } else {
      // clear app values to allow OM selectors to come through
      yield all([
        put(setDesiredDeliveryTime(null)),
        put(setDesiredDeliveryAddress(null)),
        put(setDeliveryEstimateFailed(false)),
      ]);
    }
  } catch (e) {
    debug(false, 'something went wrong performing delivery estimate', { e });
  }

  yield put(setDeliveryEstimateInProgress(false));
}
