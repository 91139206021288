// i18next documentation:
// https://www.i18next.com/translation-function/(interpolation|formatting|plurals|nesting|context)

import { OrderingConstants } from 'polygon-ordering';

import { MENU_SCREEN_ROUTE } from '../screens/MenuScreen';
import { INITIAL_SCREEN_ROUTE } from '../screens/InitialScreen';

const {
  SALE_TYPES,
  PAYMENT_METHODS,
  PAYMENT_METHOD_UNAVAILABLE_REASON,
  ORDER_SUBMITTED,
  RECEIVED_AT_STORE,
  PROCESSED_AT_STORE,
  READY_TO_COLLECT,
  PICKED_UP_BY_DRIVER,
  DELIVERED,
  FAILURE_REASON,
  STOCK_BALANCE_THRESHOLDS,
} = OrderingConstants;

const strings: Strings = {
  en: {
    translation: {
      cvcHelper: 'Please fill in all fields to show CVC',
      tieredLoyaltyPointsToThreshold: 'Spend {{currency}}{{points}} to achieve {{tierName}} Perks',
      maxValueWarning: 'Please be aware we have an Online Ordering limit of {{amount}}',
      myGiftCardDetails: 'My Details',
      deliveryDetails: 'Delivery Details',
      recipientDetails: `Recipient's Details`,
      giftCardTopUpCustomValue: 'Nominate Value',
      buyForMyself: 'Buy for myself',
      buyAsGift: 'Buy as a gift',
      memberMoneyHeader: 'MEMBER MONEY',
      giftCardMemberBalance: 'Current Balance: {{currency}}{{balance}}',
      addLoyaltyWallet: 'ADD TO LOYALTY WALLET',
      giftCardTopUp: 'TOP UP',
      checkBalance: 'CHECK BALANCE',
      purchaseGiftCard: 'PURCHASE GIFT CARD',
      giftCardHeader: 'GIFT CARD',
      updateSuccess: 'Your details have been successfully updated.',
      profileDetails: 'PERSONAL DETAILS',
      profileHeader: 'PROFILE',
      updatePassword: 'UPDATE PASSWORD',
      updatePasswordDetails: 'Update your password',
      deleteAccount: 'DELETE ACCOUNT',
      deleteAccountDetails:
        'Are you sure? If you continue all profile information will be removed and purchases history will not be accessible',
      loyaltyRewardsPointsAway:
        'You are {{remainingPoints}} points away from {{currency}}10 member money',
      offersHeader: 'OFFERS',
      claimReceiptSuccess: `You've successfully claimed this receipt.`,
      claimMissingPointsInfo: 'Enter details below to claim unassigned points',
      claimMissingPointsHeader: 'CLAIM MISSING POINTS',
      transactionsHeader: 'LOYALTY HISTORY',
      replaceCardSuccess: 'Your details have been updated.',
      replaceCardInfo:
        'If you have misplaced your card you will need to  get a replacement instore. To transfer your points onto your new card enter the details below.',
      allKey: 'All',
      replaceCardHeader: 'REPLACE CARD',
      favouriteOrdersHeader: 'FAVOURITE ORDERS',
      pastOrdersHeader: 'PAST ORDERS',
      loyaltyWallet: 'ADD TO LOYALTY WALLET',
      memberPoints: 'Member Points',
      memberMoney: 'Member Money',
      resetYourPasswordMessage:
        'An email has been sent to your email address containing instructions to reset your password.',
      resetPasswordSubtitle: 'To set your new password, please enter and confirm it below.',
      memberMoneyTitle: 'Take a look at your current balance for member points and member money',
      memberPointsTitle: 'Take a look at your current balance for member points',
      loyaltyGreeting: 'Welcome',
      termsAndContition: 'Terms And Conditions',
      termsPrefix: 'I agree to',
      message: {
        maxCharactersEnd: ' characters',
        maxCharactersStart: 'Maximum ',
        minCharactersEnd: ' characters',
        minCharactersStart: 'Minimum ',
      },
      form: {
        areRequired: 'are',
        isRequired: 'is',
        required: 'Required',
        requiredField: 'required',
      },
      memberNameFallback: 'Profile',
      loyaltyMoneyNameFallback: 'Member Money:',
      loyaltyPointsNameFallback: 'Points:',
      surchargeNameFallback: 'Surcharge',
      tableNumber: 'Table Number',

      fraudPreventionMessage: {
        collection: 'We reserve the right to ask for ID upon collection of online orders',
        delivery: 'We reserve the right to ask for ID upon delivery of online orders',
      },
      emptyCartMessage: 'Your cart is empty',
      noResultsInMenuSearchMessage: 'Your search returned no results',
      noLocationsMessage: 'No matching locations currently taking orders',
      noKnownUserLocationMessage:
        'Please ensure you have selected the store you want to order from, location services unavailable',
      minimumOrderWithCouponMessage:
        'Spend {{currency}}{{amount}} or more to meet minimum spend requirement',
      minimumOrderWarningMessage: 'Minimum order amount not met',
      minimumExcludesSurchargeMessage: '*Excludes delivery surcharges',
      invalidStagedPurchasePresentMessage: 'Invalid item in cart',
      incompatibleOfferMessage:
        'Offer not compatible with cart. Please remove offer or add compatible item',
      chargedMessage: {
        maybe: 'You may have been charged, please contact the store to resolve this issue.',
        yes: 'You have been charged, please contact the store to resolve this issue.',
        no: 'You have not been charged.',
      },
      problemUpdatingKeyOrderProperty: 'Something went wrong. Please try again.',
      problemFinalisingOrderMessage: 'Problem finalising order.',
      problemWithReCaptchaMessage: 'There was a problem with your reCAPTCHA attempt',
      problemFetchingGiftCardMessage:
        'Problem looking up gift card balance\nPlease check Number and PIN',
      successFetchingGiftCardMessage: 'Gift Card found',
      badGiftCardMessage: 'Gift Card PIN does not match',
      paymentFailedMessage: 'There was a problem with your payment',
      noValidTimeMessage: 'Please try another location or order type',
      asap: 'ASAP',
      noValidTime: '⚠️ Unavailable',
      fallbackToPickupMessage: "Don't see what you're looking for?",
      invalidTime: '⚠️ Invalid',
      flattenedDeliverySurcharge: 'Delivery Fee',
      offerTypeDivider: 'OR',
      stagedPurchaseDiscount: 'Discount Included',
      checkoutSignInPrompt: 'SIGN IN',
      checkoutSignInPromptReason: {
        guestOrderingDisabled: 'Please sign in to place your order',
        guestOrderingEnabled: 'To earn and redeem rewards',
      },
      maximumOrderAmountLabel: 'MAXIMUM',
      minimumOrderAmountLabel: 'MINIMUM',
      brandsEducationMessage:
        'We have multiple brands available from this location, use the brand menu bar to change between them and view what is available. You can add items from any of these brands into your cart.',
      preSaleMessage: {
        title: {
          [SALE_TYPES.PICKUP]: '',
          [SALE_TYPES.DELIVERY]: '',
          [SALE_TYPES.DINE_IN]: '',
          [SALE_TYPES.TAKE_AWAY]: '',
          [SALE_TYPES.CATERING]: '',
          [SALE_TYPES.TABLE_ORDER]: '',
        },
        message: {
          [SALE_TYPES.PICKUP]: '',
          [SALE_TYPES.DELIVERY]: '',
          [SALE_TYPES.DINE_IN]: '',
          [SALE_TYPES.TAKE_AWAY]: '',
          [SALE_TYPES.CATERING]: '',
          [SALE_TYPES.TABLE_ORDER]: '',
        },
      },
      metaTags: {
        description: 'Online Ordering',
      },
      mfaHelperText: 'Please check your SMS or email for the code',
      deliveryEstimateFailedMessage: 'A problem has occurred ({{reason}})',
      saveCardLabel: 'Remember',
      allBrandsLabel: 'All',
      deliveryTrackingLink: 'Track Your Order',
      menuSearchResult: '{{count}} Item',
      menuSearchResult_plural: '{{count}} Items',
      locationSearchResult: '{{count}} Location',
      locationSearchResult_plural: '{{count}} Locations',
      eta: 'Expected',
      editDeliveryDetails: 'Edit Details',
      deliveryTimeMayVaryMessage:
        'Please note that estimated delivery time may vary depending on your order value',
      creditCardMinimumNotMetMessage: 'Credit card minimum amount not met',
      offersIfNotVerified: 'Offers only available for verified members',
      profileVerificationText: 'Your profile is not verified. Please verify!',
      deleteAccountIfNotVerified: 'Only verified members can delete thier accounts',
      favouriteAccountIfNotVerified: 'Favourite orders are only available for verified members',
      claimReceiptsIfNotVerified:
        'You will be able to claim your receipt once you have verified your account!',
      purchaseStaged: {
        new: 'Added to order',
        modified: 'Saved to order',
      },
      placeholder: {
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        confirmEmail: 'Confirm Email',
        message: 'Message',
        giftcardAmount: 'Amount',
        verifyPin: 'Verify PIN',
        receiptNo: 'Sale ID',
        saleTotal: 'Sale Total',
        transactionTime: 'Purchase Time',
        transactionDate: 'Purchase Date',
        storeID: 'Location',
        loyaltyDatePicker: 'Purchase Date',
        verificationCode: 'Verification Code',
        memberNo: 'Member Number',
        giftCardNo: 'Gift Card Number',
        locationSearch: 'Search',
        menuSearch: 'Search this menu',
        promoCode: 'PROMOCODE',
        memberOffers: 'NO OFFER',
        tableNumber: 'REQUIRED',
        orderNotes: 'Any special instructions for us?',
        deliveryNotes: 'Any notes for your driver?',
      },
      title: {
        createNewFavourite: 'Create New Favourite',
        loyaltyPointsEarned: 'Points Earned',
        loyaltyTotal: 'Total',
        loyaltyPrice: 'Price',
        loyaltyPoints: 'Points',
        loyaltyQuantity: 'Qty',
        loyaltyDescriptionHeader: 'Description',
        filterSaleType: 'Sale Type',
        loyaltyDateString: 'Date',
        loyaltyTimeString: 'Time',
        filter: 'Filters',
        pastOrdersSaleID: 'Sale ID',
        modal: {
          signIn: 'Sign In',
          resetPassword: 'Reset Your Password',
          offer: {
            offerPresent: 'Selected Offer',
            offerAbsentOffersPresent: 'Apply Offer',
            offerAbsentOffersAbsent: 'Enter Code',
          },
          brandsEducation: 'Did you know?',
          memberRegister: 'Loyalty Membership System Registration',
        },
        newOrder: 'New Order',
        forYou: 'For You',
        sizeChoice: 'Size',
        saleType: 'Order Type',
        location: 'Location',
        cart: 'Cart',
        offer: 'Offer',
        notes: 'Order Notes',
        deliveryNotes: 'Driver Notes',
        pickupTime: 'Pickup Time',
        memberBalances: 'Current Balance',
        memberBalances_plural: 'Current Balances',
        paymentMethod: 'Payment Methods',
        purchaserDetails: 'Your Details',
        deliveryAddress: 'Your Address',
        deliveryTime: {
          desired: 'Desired Delivery Time',
          estimated: 'Estimated Delivery Time',
        },
        messages: 'Messages',
        orderNumber: 'Order Number',

        items: 'Order Items',
        resetPassword: 'RESET YOUR PASSWORD',
        verifyAccount: 'Verify Account',
      },
      button: {
        resendInvoice: 'Resend Invoice',
        purchaseButton: 'Purchase',
        topupButton: 'Top Up',
        giftCardBalance: 'Check Balance',
        updateProfile: 'Update Details',
        updatePassword: 'Submit',
        deleteAccount: 'Delete Account',
        orderOnline: 'ORDER ONLINE',
        claimReceipt: 'Claim Receipt',
        reOrder: 'Reorder',
        updateDetails: 'Update Details',
        resetFilters: 'Reset Filters',
        replaceCard: 'REPLACE CARD',
        transactions: 'LOYALTY HISTORY',
        claimMissingPoints: 'CLAIM MISSING POINTS',
        pastOrdersMemberButton: 'PAST ORDERS',
        favouriteOrders: 'FAVOURITE ORDERS',
        giftCard: 'GIFT CARD',
        profile: 'PROFILE',
        offers: 'OFFERS',
        rewards: 'REWARDS',
        register: 'REGISTER',
        submit: 'Submit',
        editDeliveryEstimate: 'Edit',
        signIn: 'Sign In',
        signInWithCode: 'Confirm Code',
        signOut: 'SIGN OUT',
        modalClose: {
          cancel: 'CANCEL',
          dismiss: 'CLOSE',
        },
        menuSearchCancel: 'Cancel',
        modalBack: 'BACK',
        favourites: 'Favourites',
        reorder: 'Reorder',
        pastOrders: 'Past Orders',
        headerMemberAbsent: 'SIGN IN',
        headerBack: {
          [MENU_SCREEN_ROUTE]: 'MENU',
          [INITIAL_SCREEN_ROUTE]: 'BACK',
        },
        selectLocation: {
          ready: 'PROCEED',
          notReady: 'SELECT A LOCATION',
        },

        purchaseEditor: {
          new: 'ADD ITEM',
          modified: 'SAVE ITEM',
        },
        orderSummary: {
          checkout: 'CHECKOUT',
          review: 'REVIEW ORDER',
        },
        addOffer: {
          offersPresent: 'Apply Offer or Promocode',
          offersAbsent: 'Apply Promocode',
        },
        stagedPurchase: {
          customise: 'CUSTOMISE',
          remove: 'REMOVE',
        },
        removeOffer: 'REMOVE OFFER',
        checkout: 'CHECKOUT',
        removeSavedCard: 'Remove',
        startNewOrder: 'START NEW ORDER',
        estimateDelivery: {
          check: 'CHECK AVAILABILITY',
          checking: 'CHECKING AVAILABILITY',
          confirm: 'CONFIRM',
          choose: 'CHOOSE LOCATION',
        },
        applyGiftCard: 'Apply',
        ok: 'OK',
        fallbackToPickup: 'PICK UP AVAILABLE',
        multiPayments: {
          enable: 'Use Multiple',
          disable: 'Cancel',
        },
      },
      link: {
        register: {
          prefix: 'Not a member?',
          action: 'Register Now',
        },
        forgotPassword: {
          prefix: '',
          action: 'Forgot Password?',
        },
        signIn: {
          prefix: 'Already a member?',
          action: 'Sign In',
        },
      },
      field: {
        signIn: {
          email: 'Email or Member No.',
          password: 'Password',
          mfaCode: 'Verification Code',
        },
        purchaserDetails: {
          name: 'Name',
          familyName: 'Family Name',
          email: 'Email',
          mobile: 'Mobile',
        },
        giftCard: {
          number: 'Gift Card Number',
          pin: 'PIN',
        },
      },
      confirm: {
        signOut: 'Are you sure you want to sign out?',
      },
      saleType: {
        [SALE_TYPES.PICKUP]: 'Pick Up',
        [SALE_TYPES.DELIVERY]: 'Delivery',
        [SALE_TYPES.DINE_IN]: 'Order to Table',
        [SALE_TYPES.TAKE_AWAY]: 'Take Away',
        [SALE_TYPES.WEB_ORDERING]: 'Web Order',
        [SALE_TYPES.TABLE_ORDER]: 'Table Order',
        [SALE_TYPES.CATERING]: 'Catering',
      },
      choiceSet: {
        minMobile: 'MIN',
        maxMobile: 'MAX',
        freeMobile: 'FREE',
        minDesktop: 'MINIMUM',
        maxDesktop: 'MAXIMUM',
        freeDesktop: 'FREE',
      },
      totals: {
        total: 'TOTAL:',
        discount: 'Discount:',
        pointsPrice: 'Points Cost:',
        pointsAward: 'Points Earned:',
        rewardsAward: 'Rewards Earned:',
        giftCardBalance: 'Balance Available:',
      },
      paymentMethod: {
        [PAYMENT_METHODS.CREDIT_CARD]: 'Credit Card',
        [PAYMENT_METHODS.GIFT_CARD]: 'Gift Card',
        [PAYMENT_METHODS.MEMBER_MONEY]: 'Member Cash',
        [PAYMENT_METHODS.MEMBER_POINTS]: 'Member Points',
        [PAYMENT_METHODS.MEMBER_REWARDS]: 'Member Rewards',
        [PAYMENT_METHODS.SAVED_CARD]: 'Saved Card',
        [PAYMENT_METHODS.APPLE_PAY]: 'Apple Pay',
        [PAYMENT_METHODS.GOOGLE_PAY]: 'Google Pay',
        [PAYMENT_METHODS.PAY_LATER]: {
          [SALE_TYPES.DINE_IN]: 'Table Service',
          [SALE_TYPES.TABLE_ORDER]: 'Table Service',
          [SALE_TYPES.PICKUP]: 'Pay on Collection',
          [SALE_TYPES.TAKE_AWAY]: 'Pay on Collection',
          [SALE_TYPES.DRIVE_THRU]: 'Pay on Collection',
          [SALE_TYPES.WEB_ORDERING]: 'Pay on Collection',
          [SALE_TYPES.DELIVERY]: 'Pay on Delivery',
        },
      },
      statusSteps: {
        [ORDER_SUBMITTED]: {
          incomplete: 'Submitting Order',
          complete: 'Order Submitted',
        },
        [RECEIVED_AT_STORE]: {
          incomplete: 'Receiving at Store',
          complete: 'Received at Store',
        },
        [PROCESSED_AT_STORE]: {
          incomplete: 'Processing at Store',
          complete: 'Processed at Store',
        },
        [READY_TO_COLLECT]: {
          incomplete: 'Readying Order',
          complete: {
            [SALE_TYPES.PICKUP]: 'Ready to Collect',
            [SALE_TYPES.WEB_ORDERING]: 'Ready to Collect',
            [SALE_TYPES.DELIVERY]: 'Ready for Driver', // NOTE: only for client managed delivery
            [SALE_TYPES.DINE_IN]: 'Ready',
            [SALE_TYPES.TAKE_AWAY]: 'Ready to Collect',
          },
        },
        [PICKED_UP_BY_DRIVER]: {
          incomplete: 'Ready for Driver',
          complete: 'Collected by Driver',
        },
        [DELIVERED]: {
          incomplete: 'Order in Transit',
          complete: 'Order Delivered',
        },
      },
      paymentMethodUnavailableReason: {
        [PAYMENT_METHOD_UNAVAILABLE_REASON.NOT_ENABLED]: 'Not enabled',
        [PAYMENT_METHOD_UNAVAILABLE_REASON.INSUFFICIENT_BALANCE]: 'Insufficient balance',
        [PAYMENT_METHOD_UNAVAILABLE_REASON.MEMBER_MISSING]: 'Please sign in to use',
        [PAYMENT_METHOD_UNAVAILABLE_REASON.MEMBER_INVALID]: 'Please verify to use',
        [PAYMENT_METHOD_UNAVAILABLE_REASON.SALE_TYPE_NOT_COMPATIBLE]:
          'Not available with order type',
        [PAYMENT_METHOD_UNAVAILABLE_REASON.OFFER_NOT_COMPATIBLE]: 'Not available with offer',
        [PAYMENT_METHOD_UNAVAILABLE_REASON.CART_NOT_COMPATIBLE]:
          'Some cart items cannot be redeemed',
        [PAYMENT_METHOD_UNAVAILABLE_REASON.GIFT_CARD_INACTIVE]: 'Card not activated',
        // [PAYMENT_METHOD_UNAVAILABLE_REASON.TOTAL_INVALID]: '',
        [PAYMENT_METHOD_UNAVAILABLE_REASON.DELIVERY_PROVIDER_NOT_COMPATIBLE]:
          'Not available with this delivery provider',
      },
      purchaserDetailInvalid: {
        name: 'Not Valid',
        familyName: 'Not Valid',
        mobile: 'Not Valid',
        email: 'Not Valid',
      },
      completedOrderMessage: {
        [SALE_TYPES.PICKUP]: '',
        [SALE_TYPES.DELIVERY]: '',
        [SALE_TYPES.DINE_IN]: '',
        [SALE_TYPES.TAKE_AWAY]: '',
        [SALE_TYPES.CATERING]: 'Order Confirmed',
        [SALE_TYPES.TABLE_ORDER]: '',
      },
      applyPromoCode: {
        notFound: 'Promotion not found',
        failure: 'Problem applying promotion code',
        itemAdded: 'Promotion item added to cart',
        success: 'Promotion applied',
      },
      selectOfferFailed: {
        unavailable: 'Offer unavailable',
        item: 'Offer item not available at this store',
        default: 'Could not select offer',
      },
      failureReason: {
        [FAILURE_REASON.FETCH_FAILED]: 'Unable to contact server',
        [FAILURE_REASON.LOCATION_OFFLINE]: 'Location is offline',
        [FAILURE_REASON.LOCATION_CLOSED]: 'Location closed',
        [FAILURE_REASON.UNKNOWN]: 'Problem with order',
      },
      stockBalanceThreshold: {
        [STOCK_BALANCE_THRESHOLDS.STOCK_BALANCE_THRESHOLD_0]: 'SOLD OUT',
        [STOCK_BALANCE_THRESHOLDS.STOCK_BALANCE_THRESHOLD_1]: 'LOW IN STOCK',
        // TODO: Confirm default strings for last 3 thresholds
        [STOCK_BALANCE_THRESHOLDS.STOCK_BALANCE_THRESHOLD_2]: '',
        [STOCK_BALANCE_THRESHOLDS.STOCK_BALANCE_THRESHOLD_3]: '',
        [STOCK_BALANCE_THRESHOLDS.STOCK_BALANCE_THRESHOLD_4]: '',
      },
      itemOutOfStockMessage: 'This Item Is No Longer Available',
      // itemOutOfStockAlertMessage: 'Only {{itemBalance}} of this item is available',
      itemQuantityWarningMessage: 'Only {{newItemQuantity}} x {{someLabel}} left',
      stagedChoiceSetItemQuantityWarningMessage:
        "You've added all the remaining quantities of this item",
    },
  },
};
export default strings;
