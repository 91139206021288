import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import SuperPaymentForm from '../components/SuperPaymentForm';
import PaymentProvider from '../components/PaymentProvider';
import FormTextField from '../components/FormTextField';

export const GIFT_CARD_TOP_UP_ROUTE = '/gift-card-top-up';

const GiftCardTopUp = () => {
  const { t } = useTranslation();
  const handleFormSubmit = () => {};

  const schemas = {
    MemberNo: Yup.string().required('Required'),
    VerifyPIN: Yup.string().required('Required'),
    Amount: Yup.number().required('Required'),
  };
  const validationSchema = Yup.object().shape(schemas);

  const fields = [
    {
      name: 'MemberNo',
      type: 'text',
      label: t('placeholder.giftCardNo'),
    },
    {
      name: 'VerifyPIN',
      type: 'text',
      label: t('placeholder.verifyPin'),
    },

    {
      name: 'Amount',
      type: 'number',
      label: t('placeholder.giftcardAmount'),
    },
  ];

  return (
    <PaymentProvider>
      <div className="text-center py-2">
        <Formik
          validationSchema={validationSchema}
          initialValues={{ MemberNo: '', VerifyPIN: '', Amount: '' }}
          onSubmit={handleFormSubmit}
        >
          {({ handleSubmit, handleChange }) => (
            <Form onSubmit={handleSubmit}>
              {fields.map(field => (
                <FormTextField
                  key={field.name}
                  type={field.type}
                  name={field.name}
                  label={field.label}
                  onChange={handleChange}
                />
              ))}
              <SuperPaymentForm route="giftCardTopUp" />
            </Form>
          )}
        </Formik>
      </div>
    </PaymentProvider>
  );
};

const styles: Styles = {
  button: {
    borderRadius: 5,
    height: 40,
  },
};

export default GiftCardTopUp;
