import { ActionType, getType } from 'typesafe-actions';

import { actions as submitSale } from '../../actionCreators/flows/submitSale';
import { actions as fetchSaleStatus } from '../../actionCreators/flows/fetchSaleStatus';

import * as currentOrder from '../../actionCreators/currentOrder';

import { removeUndefinedKeys } from '../../utils/misc';

const initialState = null;

export default function (
  state: Sale | null = initialState,
  action: ActionType<
    typeof currentOrder | typeof submitSale.succeeded | typeof fetchSaleStatus
  >,
): Sale | null {
  switch (action.type) {
    case getType(currentOrder.resetOrder): {
      return initialState;
    }
    case getType(currentOrder.setSaleDetails): {
      const { value } = action.payload;
      return value || initialState;
    }
    case getType(currentOrder.setSaleStatus): {
      const { value } = action.payload;
      return { ...state, status: value };
    }
    case getType(submitSale.succeeded): {
      const { saleDetails } = action.payload;
      return saleDetails || initialState;
    }
    case getType(fetchSaleStatus.succeeded): {
      const { saleDetails } = action.payload;
      return state
        ? { ...state, ...removeUndefinedKeys(saleDetails) }
        : saleDetails || initialState;
    }
    default:
      return state;
  }
}
