import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import StandardButton from '../components/StandardButton';
import getProfileInitialValues from '../selectors/getProfileInitialValues';
import getMemberFields from '../selectors/getMemberFields';
import getMemberSchema from '../selectors/getMemberSchema';
import getProfile from '../selectors/getProfile';
import { RedcatApiHandler } from 'polygon-utils';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../utils/snackbar';
import syncMember from '../actions/syncMember';
import ProfileFields from './ProfileFields';
import { Formik, Form, FormikHelpers } from 'formik';
import Text from '../components/Text';
import SendVerificationButton from '../components/SendVerificationButton';

export const PROFILE_ROUTE = '/profile';

const ProfileScreen = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const initialVal = useAppSelector(getProfileInitialValues);
  const memberFields = useAppSelector(getMemberFields);
  const memberSchema = useAppSelector(getMemberSchema);
  const profile = useAppSelector(getProfile);
  const submit = (values: Partial<RegisterFields>, formikHelpers: FormikHelpers<FormikFields>) => {
    const { setSubmitting, resetForm } = formikHelpers;
    const path = '/api/v1/profile';
    RedcatApiHandler.authorisedFetch({
      path,
      method: 'PUT',
      body: values,
    })
      .then(response => {
        //@ts-ignore
        if (response.token) {
          dispatch(syncMember());
        }
        if (response.success) {
          enqueueSuccessSnackbar(t('updateSuccess'));
          setSubmitting(false);
          resetForm({ values });
        }
      })
      .catch(err => {
        enqueueErrorSnackbar(err);
      });
  };

  return (
    <div>
      {!profile?.verified ? (
        <div style={{ textAlign: 'center', padding: '20px 0' }}>
          <Text value={t('profileVerificationText')} style={{ fontSize: 14 }} />
          <SendVerificationButton containerStyle={{ paddingTop: 20 }} />
        </div>
      ) : null}
      <div style={{ padding: '20px 0' }}>
        <Text value={t('profileDetails')} style={{ fontSize: 16 }} />
      </div>
      <Formik
        validationSchema={memberSchema.visible}
        initialValues={initialVal.visible}
        onSubmit={submit}
        enableReinitialize={true}
      >
        {({ handleSubmit, isSubmitting, isValid, dirty }) => {
          return (
            <Form onSubmit={handleSubmit}>
              {memberFields?.visible.map((field: FormikFields) => (
                <ProfileFields field={field} key={field.id} />
              ))}
              <div className="position-relative" style={{ marginTop: 20 }}>
                <StandardButton
                  containerStyle={styles.button}
                  themeKey="loyaltyButton"
                  label={t('button.updateProfile')}
                  onClick={handleSubmit}
                  disabled={isSubmitting || !isValid || !dirty}
                  showSpinner={isSubmitting}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const styles: Styles = {
  button: {
    borderRadius: 5,
    height: 40,
  },
};

export default ProfileScreen;
