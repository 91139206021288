import React, { useState, useEffect } from 'react';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe, TokenResult, PaymentIntentResult } from '@stripe/stripe-js';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import {
  OrderingPaymentHooks,
  OrderingConstants,
  OrderingOperations,
  OrderingSelectors,
} from 'polygon-ordering';

import getThemeLookup from '../selectors/getThemeLookup';
import getPaymentGatewayPublicKey from '../selectors/getPaymentGatewayPublicKey';
import { setCreditCardComplete } from '../slices/creditCardComplete';
import EVENTS from '../constants/events';
import { logEvent } from '../utils/analytics';
import debug from '../utils/debug';
import delay from '../utils/delay';
import combineStyles from '../utils/combineStyles';
import { CONTAINER_PROPERTIES } from '../utils/theme';
import Text from './Text';
import PaymentMethod from './PaymentMethod';
import SaveCardCheckbox from './SaveCardCheckbox';
import getProfile from '../selectors/getProfile';
import '../styles/stripe.css';
import { useTranslation } from 'react-i18next';

const { GATEWAY_REQUIRES_ACTION } = OrderingConstants;
const { fetchPaymentGatewayConfig, updateSelectedPaymentMethods } = OrderingOperations;
const { getSelectedPaymentMethods, getEnabledPaymentMethods } = OrderingSelectors;

// Only way to access stripe and elements instances is by using a child component to <Elements/>.
// We have to use the hooks, so it has to be a functional component.
// https://github.com/stripe/react-stripe-elements

// "You must provide a Stripe Element or a valid token type to create a Token"

const PaymentHookSubscriber = ({ resetStripe }: { resetStripe: () => void }) => {
  const stripe = useStripe();
  const elements = useElements();

  // debug('cc subscriber', { stripe, elements });

  if (!stripe || !elements) {
    return null;
  }

  OrderingPaymentHooks.subscribe(
    PAYMENT_METHOD.CREDIT_CARD,
    //@ts-ignore
    async ({ name, familyName, email, mobile, enableDynamicPaymentGatewayConfig }) => {
      const cardElement = elements.getElement(CardElement);

      debug('cc hook', { stripe, elements, cardElement });

      if (!cardElement) {
        console.error('CardElement not found', { stripe, elements, cardElement });
      }

      const fullName = `${name || ''} ${familyName || ''}`.trim();

      if (enableDynamicPaymentGatewayConfig && cardElement) {
        return await stripe
          .createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
              name: fullName,
              //@ts-ignore
              email: email,
              //@ts-ignore
              phone: mobile,
            },
          })
          .then(({ paymentMethod, error }) => {
            debug({ paymentMethod, error });

            if (error) {
              console.error(error);
            }

            return {
              token: paymentMethod?.id,
              lastFour: paymentMethod?.card?.last4,
            };
          })
          .catch(e => {
            debug('cc hook catch', e);
            throw e;
          });
      } else {
        if (cardElement) {
          return await stripe
            .createToken(cardElement, {
              name: fullName,
            })
            .then((value: TokenResult) => {
              const { token, error } = value;

              debug({ token, error });

              if (error) {
                console.error(error);
              }

              return {
                token: token?.id,
                lastFour: token?.card?.last4,
              };
            });
        }
      }
    },
  );

  OrderingPaymentHooks.subscribe(
    GATEWAY_REQUIRES_ACTION,
    //@ts-ignore
    async ({ serverData, paymentGatewayConfig }) => {
      const { action, orderInfo } = serverData;

      if (!paymentGatewayConfig) {
        const errorMessage =
          'missing paymentGatewayConfig. please check payment gateway has been configured';
        console.error(errorMessage);

        alert(errorMessage);
      }

      //@ts-ignore
      return loadStripe(paymentGatewayConfig.clientKey, {
        //@ts-ignore
        stripeAccount: paymentGatewayConfig.merchantAccount,
      })
        .then(stripe2 => stripe2?.handleCardAction(action.client_secret))
        .then((result?: PaymentIntentResult) => {
          if (result) {
            const { paymentIntent, error } = result;
            if (error) {
              resetStripe();

              return {
                success: false,
                error,
              };
            }
            return {
              success: true,
              data: {
                // added directly to body of finalise sale packet
                orderInfo: orderInfo,
                actionResponse: {
                  payment_intent_id: paymentIntent?.id,
                },
              },
            };
          }
        })
        .catch(e => {
          debug('cc handle action hook catch', e);
          resetStripe();
          throw e;
        });
    },
  );

  return null;
};

const PaymentMethodCreditCard = () => {
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const p = useAppSelector(getThemeLookup);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const paymentGatewayPublicKey = useAppSelector(getPaymentGatewayPublicKey);
  const selectedPaymentMethods = useAppSelector(getSelectedPaymentMethods);
  const profile = useAppSelector(getProfile);

  useEffect(() => {
    dispatch(setCreditCardComplete(false));
    readyStripe();
  }, [paymentGatewayPublicKey]);

  const readyStripe = () => {
    if (!paymentGatewayPublicKey) {
      return;
    }

    setStripe(null);

    delay(1000)
      .then(() => loadStripe(paymentGatewayPublicKey))
      .then(stripe => setStripe(stripe));
  };

  const alreadySelected = selectedPaymentMethods.some(selectedPaymentMethod => {
    if (selectedPaymentMethod.method === PAYMENT_METHOD.CREDIT_CARD) {
      return true;
    }
    return false;
  });

  const enabledPaymentMethods = useAppSelector(getEnabledPaymentMethods);
  const rememberCardEnabled = (enabledPaymentMethods || []).includes(PAYMENT_METHOD.SAVED_CARD);

  return (
    <Elements key={paymentGatewayPublicKey} stripe={stripe}>
      <PaymentMethod
        method={PAYMENT_METHOD.CREDIT_CARD}
        right={
          alreadySelected && profile?.verified && rememberCardEnabled ? (
            <SaveCardCheckbox />
          ) : undefined
        }
        subtitle={
          <div>
            <Text themeKey="paymentMethodUnavailableReason">&nbsp;</Text>
            {alreadySelected && (
              <span id="cvc-helper">
                <Text themeKey="cvcHelper">{t('cvcHelper')}</Text>
              </span>
            )}
          </div>
        }
      >
        <div style={styles.contentContainer}>
          {Boolean(stripe) && (
            <div style={combineStyles(styles.input, p('input', CONTAINER_PROPERTIES))}>
              <CardElement
                options={{
                  hidePostalCode: true,
                  style: {
                    base: {
                      backgroundColor: 'transparent', // workaround for issue with darkmode
                    },
                  },
                }}
                onChange={event => dispatch(setCreditCardComplete(event.complete))}
                onFocus={() => {
                  if (!alreadySelected) {
                    dispatch(
                      updateSelectedPaymentMethods({
                        paymentMethod: PAYMENT_METHOD.CREDIT_CARD,
                        alreadySelected,
                      }),
                    );
                  }
                  logEvent(EVENTS.CHANGE_PAYMENT_METHOD, {
                    label: PAYMENT_METHOD.CREDIT_CARD,
                  });
                }}
              />
            </div>
          )}
        </div>
      </PaymentMethod>
      <PaymentHookSubscriber
        resetStripe={() => {
          dispatch(fetchPaymentGatewayConfig({}));
          readyStripe();
        }}
      />
    </Elements>
  );
};

const styles = {
  contentContainer: {
    minHeight: 57,
  },
  input: {
    borderRadius: 4,
    border: '1px solid #c4c4c4',
  },
};

export default PaymentMethodCreditCard;
